import { CardBlock } from '@sbt-web/ancillary-services';
import { useViewport } from '@sbt-web/hooks';
import { AdItem, HebeCampaignPosition } from '@sbt-web/networking';
import { Headline6 } from '@sbt-web/ui';
import { prepareRemoteDataAdDetail } from '@shared/helpers/Hebe';
import { getOrCreatePulse } from '@tools/tracking/utils';
import classnames from 'classnames';
import React from 'react';
import FeatureList, { Feature } from '../../FeatureList';
import type { SectionProps } from './section';
import ownClasses from './style/vehicle-history-section.module.scss';

type Props = SectionProps & {
  features: Feature[];
  adItem: AdItem;
};

export function VehicleHistorySection({
  features,
  classes = [],
  adItem,
}: Props) {
  return (
    <section className={classnames(...classes)}>
      <Headline6 classes={[ownClasses['vehicle-history-title']]} element="h2">
        Storia del veicolo
      </Headline6>
      <FeatureList features={features} />
      <CardBlock
        pulseInstance={getOrCreatePulse()}
        background
        bannerButtonDesign="outline"
        item={adItem}
        remoteData={prepareRemoteDataAdDetail(
          HebeCampaignPosition.BELOW_HISTORY,
          adItem,
          useViewport().isMobile
        )}
        classes={[ownClasses['vehicle-history-container']]}
      />
    </section>
  );
}

export default VehicleHistorySection;
