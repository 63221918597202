import { slugify } from '@sbt-web/utils';

const createShopUrl = (
  shopBase: string,
  shopId: string,
  shopName: string
): string => {
  return `${shopBase}/shops/${shopId}-${slugify(shopName)}`;
};

export default createShopUrl;
