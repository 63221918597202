import { AdsAdDetailsBlock } from '@client/components/Adv/AdSense/adDetails/AdDetailsBlock';
import {
  AdsGAMPlacement,
  DesktopAdDetailGAMIds,
  MsiteAdDetailGAMIds,
} from '@client/components/Adv/GPT';
import { CardBlock } from '@sbt-web/ancillary-services';
import { getPublicUser } from '@sbt-web/auth';
import { AvailableIcons, SpecialCampaignBanner } from '@sbt-web/banners';
import { useViewport } from '@sbt-web/hooks';
import {
  CategoryId,
  FeatureURI,
  HebeCampaignPosition,
  PromoInfo,
} from '@sbt-web/networking';
import { Divider } from '@sbt-web/ui';
import flattenFeatures from '@shared/helpers/FlattenFeatures';
import { prepareRemoteDataAdDetail } from '@shared/helpers/Hebe';
import { getOrCreatePulse } from '@tools/tracking/utils';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ShippingMethods } from '../TuttoSubitoInfo/ShippingMethods';
import { PaymentMethods } from '../TuttoSubitoInfo/PaymentMethods';
import { ShippingCosts } from 'server/detail-requests/ShippingCost';
import InternalLinksFooter from '../../SEO/InternalLinks/Footer';
import InternalLinksHeader from '../../SEO/InternalLinks/Header';
import type { Props as CommonProps } from './common-props';
import StickyBar from '../StickyBar';
import { getTransactionFlows } from '@shared/tools/transaction/utils';
import {
  AdvertiserInfoSection,
  BottomSection,
  DescriptionSection,
  FeatureListSection,
  GallerySection,
  GeneralInfoSection,
  MapSection,
  RelatedAds,
  SafetySection,
  SkyscraperSection,
} from './Layout';
import NavigationRow from '../NavigationRow';
import { AdvDetailContext } from '@client/components/Adv/AdvAdDetailContext';
import { LazyPrestiPayBox } from '@client/components/Detail/PrestiPayBoxAncillaryService';
import { isPro } from '@shared/models/Advertiser';
import { evalBoolFeature } from '@shared/models';
import '@sbt-web/banners/style';

import css from './style/container.module.scss';
import gridStyles from './style/grid.module.scss';

const dividerClasses = [
  gridStyles['detail-component'],
  gridStyles['section-divider'],
];

const defaultDesktopRightBlockRowSpan = 6;

type Props = CommonProps & {
  promo: PromoInfo | null;
  shippingCosts: ShippingCosts | null;
};

const areSafetyTipsActive = (categoryId: CategoryId) =>
  categoryId == CategoryId.ConsoleVideogiochi ||
  categoryId == CategoryId.Informatica ||
  categoryId == CategoryId.AudioVideo ||
  categoryId == CategoryId.Fotografia ||
  categoryId == CategoryId.Telefonia ||
  categoryId == CategoryId.Elettronica ||
  categoryId == CategoryId.CasaPersona ||
  categoryId == CategoryId.ArredamentoCasalinghi ||
  categoryId == CategoryId.GiardinoFaiDaTe ||
  categoryId == CategoryId.AbbigliamentoAccessori ||
  categoryId == CategoryId.TuttoBambini ||
  categoryId == CategoryId.SportsHobby ||
  categoryId == CategoryId.MusicaFilm ||
  categoryId == CategoryId.LibriRiviste ||
  categoryId == CategoryId.StrumentiMusicali ||
  categoryId == CategoryId.Sports ||
  categoryId == CategoryId.Biciclette ||
  categoryId == CategoryId.Collezionismo ||
  categoryId == CategoryId.Animali;

export function DefaultContainer({
  ad,
  internalLinks,
  shop,
  favoriteCounter,
  advertiserProfile,
  trustInfo,
  promo,
  shippingCosts,
}: Props) {
  const { isMobile, isDesktop } = useViewport();
  const features = flattenFeatures(ad.features, ad.geo);
  const mapDetails = ad.geo.map;
  const hasMap = mapDetails?.longitude != null && mapDetails?.latitude != null;
  const isSoldItem = ad.features[FeatureURI.TransactionStatus] != undefined;
  // This is an overly-complex workaround because `getPublicUser` can't be
  // called on the server
  const [userId, setUserId] = useState<string | null | undefined>(null);
  const transactionFlows = getTransactionFlows(
    ad.features,
    ad.advertiser,
    userId
  );

  const thereIsNoPlaceForContactButtonElsewhere = transactionFlows.length === 2;
  const userIsSeller = userId === ad.advertiser.userId;
  const isShippableItem = evalBoolFeature(
    ad.features[FeatureURI.ItemShippable]
  );
  const isTransactionsSectionEnabled =
    isShippableItem && !isSoldItem && shippingCosts !== null;

  useEffect(() => {
    if (__BROWSER__) {
      setUserId(getPublicUser()?.id);
    }
  }, []);

  const hasSafetyTips = areSafetyTipsActive(ad.category.id);

  const [desktopRightBlockRowSpan, setDesktopRightBlockRowSpan] = useState(
    () => {
      let result = defaultDesktopRightBlockRowSpan;

      if (features.length > 0) {
        result += 2;
      }

      if (hasMap) {
        result += 1;
      }

      if (isSoldItem && userIsSeller) {
        result -= 1;
      }

      // The TuttoSubito section is only rendered for shippable ads.
      if (isTransactionsSectionEnabled) {
        result += 4;
      }

      if (hasSafetyTips) {
        result += 1;
      }

      return result;
    }
  );

  const increaseRowSpan = useCallback(
    () => setDesktopRightBlockRowSpan((span) => span + 1),
    []
  );

  const promoHasCountdown = !!promo?.content.countdown?.alternativeSubtitle;
  const promoSubtitle = promoHasCountdown
    ? promo?.content?.countdown?.alternativeSubtitle
    : promo?.content.subtitle;

  const advContext = useContext(AdvDetailContext);
  const { showBannerBelowGallery } = advContext;

  return (
    <>
      {internalLinks !== null ? (
        <div
          className={classnames(
            gridStyles['detail-component'],
            gridStyles['navigation-detail-container']
          )}
        >
          <InternalLinksHeader links={internalLinks.header} />
        </div>
      ) : null}
      <div className={css['outer-ad-container']}>
        <div
          className={classnames(
            css['inner-ad-container'],
            gridStyles['detail-container']
          )}
          style={{ '--right-block-row-span': desktopRightBlockRowSpan }}
        >
          {/* Not counted in desktop row span: it's above the right-side's block */}
          <section
            className={classnames([
              gridStyles['detail-component'],
              gridStyles['navigation-detail-container'],
            ])}
          >
            <NavigationRow item={ad} />
          </section>

          {/* 1 desktop row */}
          <GallerySection
            classes={[gridStyles['detail-component'], gridStyles['gallery']]}
            ad={ad}
          />

          {isMobile && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={MsiteAdDetailGAMIds.ANCHOR}
              fixedHeight={50}
            />
          )}

          {/* This is the right-side container */}
          <div
            className={classnames(
              gridStyles['detail-component'],
              gridStyles['right-container']
            )}
          >
            <GeneralInfoSection
              classes={[
                gridStyles['ad-info-container'],
                gridStyles['ad-info-override'],
              ]}
              ad={ad}
              shop={shop}
              favoriteCounter={favoriteCounter}
              advertiserProfile={advertiserProfile}
              shippingCosts={shippingCosts}
              trustInfo={trustInfo}
              promo={promo}
              showContactButton={thereIsNoPlaceForContactButtonElsewhere}
            />
            {isDesktop && (
              <SkyscraperSection item={ad} classes={[gridStyles.skyscraper]} />
            )}
          </div>
          {!isSoldItem && isDesktop && promo && promoSubtitle && (
            <div className={gridStyles['detail-component']}>
              <SpecialCampaignBanner
                title={promo?.content.title}
                description={promoSubtitle}
                iconName={promo?.content.assets.resourceName as AvailableIcons}
                countdownTo={promoHasCountdown ? promo?.endDate : undefined}
                alternativeExternalIconURL={promo?.content.assets.resourceUrl}
              />
            </div>
          )}

          {showBannerBelowGallery && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={DesktopAdDetailGAMIds.BELOW_GALLERY}
            />
          )}

          {/* 2 desktop rows */}
          <DescriptionSection
            ad={ad}
            classes={[gridStyles['detail-component'], gridStyles.description]}
          />

          {/* 3 desktop rows */}
          <Divider spacing="null" classes={dividerClasses} />

          {!isDesktop && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={MsiteAdDetailGAMIds.BOX1}
              fixedHeight={250}
            />
          )}

          {features.length > 0 && (
            <FeatureListSection
              features={features}
              classes={[gridStyles['detail-component']]}
            />
          )}

          {hasMap && (
            <MapSection
              classes={[gridStyles['detail-component'], gridStyles.map]}
              map={mapDetails}
            />
          )}

          {isTransactionsSectionEnabled && (
            <>
              <Divider spacing="null" classes={dividerClasses} />
              <section className={classnames(gridStyles['detail-component'])}>
                <ShippingMethods costs={shippingCosts} />
              </section>
              <Divider spacing="null" classes={dividerClasses} />
              <section className={gridStyles['detail-component']}>
                <PaymentMethods isPro={isPro(ad.advertiser.type)} />
              </section>
            </>
          )}

          {/* 4 desktop rows */}
          <div
            className={classnames(gridStyles['detail-component'], 'card-block')}
          >
            <CardBlock
              padding
              background
              pulseInstance={getOrCreatePulse()}
              bannerButtonDesign="outline"
              item={ad}
              remoteData={prepareRemoteDataAdDetail(
                HebeCampaignPosition.BELOW_FEATURES,
                ad,
                isMobile
              )}
            />
          </div>

          {isMobile ? (
            <section className={gridStyles['detail-component']}>
              <LazyPrestiPayBox
                price={ad.features['/price']}
                assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
                item={ad}
                pulseInstance={getOrCreatePulse()}
              />
            </section>
          ) : null}

          {/* 5 desktop rows */}
          <Divider spacing="null" classes={dividerClasses} />

          {/* 6 desktop rows */}
          <AdvertiserInfoSection
            classes={[gridStyles['detail-component']]}
            item={ad}
            shop={shop}
            advertiserProfile={advertiserProfile}
            trustInfo={trustInfo}
          />

          {hasSafetyTips && (
            <SafetySection
              classes={[gridStyles['detail-component']]}
              category={ad.category.id}
              urn={ad.urn}
            />
          )}

          <RelatedAds
            item={ad}
            className={gridStyles['detail-component']}
            // This is conditional: not a guaranteed row
            onActualRender={increaseRowSpan}
          />
        </div>

        {!isMobile && (
          <AdsAdDetailsBlock
            listingCategory={ad.category.id}
            className={gridStyles['detail-component']}
          />
        )}

        {isMobile || (isSoldItem && userIsSeller) ? null : (
          <StickyBar item={ad} />
        )}

        {!isDesktop && <AdsGAMPlacement id={MsiteAdDetailGAMIds.BOX2} />}
      </div>

      <BottomSection item={ad} />
      {internalLinks !== null ? (
        <InternalLinksFooter links={internalLinks.footer} />
      ) : null}

      {isDesktop && <div id="apn_body" />}
    </>
  );
}

export default DefaultContainer;
