import { type AdItem, CategoryId } from '@sbt-web/networking';
import { isPro } from '@shared/models/Advertiser';
import { getTransactionFlows, TransactionFlow } from '@tools/transaction/utils';

const showPhoneCategories = [
  CategoryId.AccessoriAuto,
  CategoryId.Elettronica,
  CategoryId.Informatica,
  CategoryId.AudioVideo,
  CategoryId.Telefonia,
  CategoryId.ArredamentoCasalinghi,
  CategoryId.GiardinoFaiDaTe,
  CategoryId.AbbigliamentoAccessori,
  CategoryId.TuttoBambini,
  CategoryId.SportsHobby,
  CategoryId.MusicaFilm,
  CategoryId.Sports,
  CategoryId.Collezionismo,
  CategoryId.Altri,
  CategoryId.AltriAnnunci,
  CategoryId.AccessoriMoto,
  CategoryId.Elettrodomestici,
  CategoryId.LibriRiviste,
  CategoryId.StrumentiMusicali,
  CategoryId.Fotografia,
  CategoryId.Biciclette,
  CategoryId.ConsoleVideogiochi,
  CategoryId.AccessoriAnimali,
];

const isPhoneProtectionEnabled = (categoryId: CategoryId): boolean =>
  showPhoneCategories.includes(categoryId);

/**
 * @description Return true if advertiser is pro without transactions and has a phone number, or if not protected and has a phone number
 */
export const getShowPhone = (item: AdItem, userId?: string | null): boolean => {
  const transactionFlows = getTransactionFlows(
    item.features,
    item.advertiser,
    userId
  );

  return isPro(item.advertiser.type)
    ? !!item.advertiser.phone &&
        !transactionFlows.includes(TransactionFlow.BuyNow)
    : !isPhoneProtectionEnabled(item.category.id) && !!item.advertiser.phone;
};
