import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { sendEventToGTM } from '@sbt-web/tracking';
import { urnTransformer } from '@sbt-web/utils';
import { LoginTemplate, type PublicUser } from '@sbt-web/auth';
import { useViewport } from '@sbt-web/hooks';
import {
  AdItem,
  CaerusClient,
  FeatureURI,
  HTTPStatusCode,
} from '@sbt-web/networking';
import {
  UsernameDialogAction,
  UsernameDialogEvent,
} from '@client/components/Detail/UsernameDialog';
import { FlowDialog } from '@client/components/Detail/FlowDialog';
import { getOrCreatePulse } from '@tools/tracking/utils';
import parsePrice from '@client/utilities/parse-price';
import { setPreventPopstate } from '@reducers/system';
import { setDialogInfo } from '@reducers/user';
import { RootState } from '@reducers/index';
import { AdBuyButtons } from './components/AdBuyButtons';

type flowType = 'unguided' | 'buynow';

type OwnProps = {
  hideButtons?: boolean;
  item: AdItem;
};

type StateProps = {
  userId: string;
};

type DispatchProps = {
  requestLogin: (cb: (user: PublicUser) => void) => void;
};

export type AdBuyProps = OwnProps & StateProps & DispatchProps;

export const AdBuyEvent = {
  OpenOfferDialog: 'subito:AdBuy:OpenOfferDialog',
  OpenCartDialog: 'subito:AdBuy:OpenCartDialog',
};

export const AdBuy = ({
  userId,
  requestLogin,
  item,
  hideButtons = false,
}: AdBuyProps) => {
  const { isMobile } = useViewport();
  const [flowUrl, setFlowUrl] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const userIdRef = useRef<string | undefined>(userId);
  const userWantsToBuyNow = useRef<boolean>();

  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);

  const itemIdInfo = urnTransformer(item.urn);
  const itemIdForMessaging = `ad${itemIdInfo?.adId}list${itemIdInfo?.contentId}`;
  const { optimizely } = useContext(OptimizelySubitoContext);

  const trackClickGTM = useCallback(() => {
    window.dataLayer?.push({ ecommerce: null });
    sendEventToGTM('add_to_cart', {
      ecommerce: {
        currency: 'EUR',
        items: [
          {
            item_name: item.subject,
            item_id: itemIdForMessaging,
            price: parsePrice(item.features[FeatureURI.Price]),
            currency: 'EUR',
            item_category: item.category.friendlyName,
          },
        ],
      },
    });
  }, [item, itemIdForMessaging]);

  const trackClick = useCallback(
    (flow: flowType) => {
      const ownerId = item.advertiser.userId;

      if (ownerId) {
        getOrCreatePulse()?.queueEvent({
          type: 'Click',
          object: {
            '@type': 'UIElement',
            '@id': 'sdrn:subito:content:shipment:element:buy_ad_detail',
            inReplyTo: {
              '@id': `sdrn:subito:classified:${item.urn}`,
            },
            label: flow === 'buynow' ? 'Acquista' : 'Fai una Proposta',
          },
          target: {
            id: `sdrn:subito:user:${ownerId}`,
            '@type': 'Account',
            accountId: ownerId,
          },
        });
      }
    },
    [item]
  );

  const showLoginDialog = useCallback(async () => {
    try {
      const user: PublicUser = await new Promise((resolve) =>
        requestLogin(resolve)
      );

      userIdRef.current = user.id;
      chooseDialogToShow();
    } catch (e) {
      console.warn('LOGIN ERROR');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chooseDialogToShow = useCallback(async () => {
    if (!userIdRef.current) {
      await showLoginDialog();
      return;
    }

    window.dispatchEvent(
      new CustomEvent(UsernameDialogEvent.CheckBuyerName, {
        detail: {
          action: userWantsToBuyNow.current
            ? UsernameDialogAction.BuyNow
            : UsernameDialogAction.SendOffer,
          userId: userIdRef.current,
        },
      })
    );
  }, [showLoginDialog]);

  const openTransactionFlow = useCallback(async () => {
    const client = new CaerusClient(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      'web'
    );

    const { transactionFlow, status } = await client.create(
      itemIdForMessaging,
      'ad',
      userWantsToBuyNow.current
    );

    if (status === HTTPStatusCode.Unauthorized) {
      userIdRef.current = undefined;
      return chooseDialogToShow();
    }

    const resUrl = transactionFlow?.flowUrl;

    if (resUrl) {
      const fullUrl = new URL(resUrl);
      fullUrl.searchParams.set('entrypoint', 'adDetail');
      setFlowUrl(fullUrl.toString());
      setOpenModal(true);
    }
  }, [chooseDialogToShow, itemIdForMessaging]);

  const sendTrackings = useCallback(
    (flow: flowType) => {
      optimizely?.onReady().then(() => {
        optimizely.track(
          'Click_CTA_Acquista_or_Fai_una_Proposta_from_ad_detail'
        );
      });
      trackClick(flow);
      trackClickGTM();
    },
    [optimizely, trackClick, trackClickGTM]
  );

  const handleOfferClick = useCallback(() => {
    userWantsToBuyNow.current = false;
    sendTrackings('unguided');
    chooseDialogToShow();
  }, [chooseDialogToShow, sendTrackings]);

  const handleBuyClick = useCallback(() => {
    userWantsToBuyNow.current = true;
    sendTrackings('buynow');
    chooseDialogToShow();
  }, [chooseDialogToShow, sendTrackings]);

  const handleGetBuyerName: EventListener = useCallback(
    (event) => {
      if (
        event instanceof CustomEvent &&
        (event.detail?.action === UsernameDialogAction.BuyNow ||
          event.detail?.action === UsernameDialogAction.SendOffer)
      ) {
        openTransactionFlow();
      }
    },
    [openTransactionFlow]
  );

  useEffect(() => {
    window.addEventListener(
      UsernameDialogEvent.GetBuyerName,
      handleGetBuyerName
    );

    return () => {
      window.removeEventListener(
        UsernameDialogEvent.GetBuyerName,
        handleGetBuyerName
      );
    };
  }, [handleGetBuyerName]);

  useEffect(() => {
    window.addEventListener(AdBuyEvent.OpenOfferDialog, handleOfferClick);
    window.addEventListener(AdBuyEvent.OpenCartDialog, handleBuyClick);
    return () => {
      window.removeEventListener(AdBuyEvent.OpenOfferDialog, handleOfferClick);
      window.removeEventListener(AdBuyEvent.OpenCartDialog, handleBuyClick);
    };
  }, [handleBuyClick, handleOfferClick]);

  return (
    <>
      {!hideButtons && (
        <AdBuyButtons
          item={item}
          userId={userIdRef.current}
          onBuyNowButtonClick={handleBuyClick}
          onSendOfferButtonClick={handleOfferClick}
        />
      )}
      <FlowDialog
        fullscreen={isMobile}
        opened={openModal}
        onCloseIntent={() => {
          userWantsToBuyNow.current = undefined;
          setFlowUrl('');
          setOpenModal(false);
        }}
        flowUrl={flowUrl}
      />
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  userId: state.user.data?.id ?? '',
});

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  (dispatch) => ({
    requestLogin: (cb) => {
      dispatch(setDialogInfo(true, LoginTemplate.AdBuy, cb));
      dispatch(setPreventPopstate(true));
    },
  })
)(AdBuy);
