import { FeatureURI, ItemFeature } from '@sbt-web/networking';
import type { BadgeConfiguration, RenderPage } from '@sbt-web/ui';
import { evalBoolFeature } from '@shared/models';

const formatShippingInfo = (
  features: { [uri: string]: ItemFeature },
  displayLocation: RenderPage
): BadgeConfiguration => ({
  shippable: evalBoolFeature(features[FeatureURI.ItemShippable]),
  isItemSold: features[FeatureURI.TransactionStatus] != undefined,
  display: displayLocation,
});

export default formatShippingInfo;
