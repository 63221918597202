import React, { useCallback, useContext, useMemo, useState } from 'react';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import {
  Button,
  ButtonSize,
  ButtonDesign,
  Icon,
  IconSize,
  ShortModal,
} from '@sbt-web/ui';
import {
  CategoryId,
  ClothoClient,
  HTTPStatusCode,
  type AdItem,
} from '@sbt-web/networking';

import { isPro } from '@shared/models/Advertiser';
import { HADES_PATH } from '@shared/constants';
import type { SearchData } from '../../../types';
import {
  phoneCallGTMEvent,
  phoneContactEvent,
  showPhoneContactGTMEvent,
} from '../../../tracking';

import classes from './index.module.scss';

export type PulseEvent = {
  name: string;
  pageType: 'ad_detail' | 'listing';
};

export type PhoneButtonProps = {
  isMobile: boolean;
  adUrn: string;
  item: AdItem;
  search?: SearchData;
  buttonSize: ButtonSize;
  buttonDesign: ButtonDesign;
  buttonLabelDesktop?: string;
  buttonLabelMobile?: string;
  buttonShowIcon?: boolean;
  buttonClass?: string;
  pulseEvent: PulseEvent;
};

interface PhoneButtonDialogProps {
  phoneNumber: string;
  dialogOpened: boolean;
  handleClose: () => void;
}

const PhoneButtonDialog = ({
  phoneNumber,
  dialogOpened,
  handleClose,
}: PhoneButtonDialogProps) => {
  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(phoneNumber);
    } catch (err) {
      console.error('Failed to copy: ', err);
    } finally {
      handleClose();
    }
  };

  return (
    <ShortModal
      title="Numero di telefono"
      open={dialogOpened}
      onClose={handleClose}
      buttonConfiguration={{
        main: {
          action: () => {
            copyContent();
          },
          text: 'Copia',
          design: 'solid',
        },
      }}
    >
      <address className={classes.number}>{phoneNumber}</address>
    </ShortModal>
  );
};

export const PhoneButton = ({
  isMobile,
  adUrn,
  search,
  item,
  buttonDesign,
  buttonSize,
  buttonLabelDesktop,
  buttonLabelMobile,
  buttonShowIcon = true,
  buttonClass,
  pulseEvent,
}: PhoneButtonProps) => {
  const {
    phoneNumber,
    dialogOpened,
    handleMobileClick,
    handleDesktopClick,
    closeDialog,
  } = usePhoneNumberHandler(adUrn, item, pulseEvent, search);

  let buttonLabel = null;

  if (buttonLabelMobile && buttonLabelDesktop) {
    buttonLabel = isMobile ? buttonLabelMobile : buttonLabelDesktop;
  }

  return (
    <>
      {!isMobile && (
        <PhoneButtonDialog
          dialogOpened={dialogOpened}
          phoneNumber={phoneNumber ?? ''}
          handleClose={closeDialog}
        />
      )}

      <Button
        design={buttonDesign}
        onClick={isMobile ? handleMobileClick : handleDesktopClick}
        size={buttonSize}
        icon={
          buttonShowIcon ? (
            <Icon name="Phone" iconSize={IconSize.MD} />
          ) : undefined
        }
        classes={[classes.phoneButton, buttonClass ?? '']}
        aria-label={isMobile ? 'Chiama' : 'Mostra numero di telefono'}
        slim={true}
      >
        {buttonLabel}
      </Button>
    </>
  );
};

const usePhoneNumberHandler = (
  adUrn: string,
  item: AdItem,
  pulseEvent: PulseEvent,
  search?: SearchData
) => {
  const clothoClient = useMemo(() => new ClothoClient(HADES_PATH, 'web'), []);
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [dialogOpened, setDialogOpened] = useState(false);

  const trackMakeAnOfferShowPhoneNumber = () => {
    if (item.category.id === CategoryId.Auto && isPro(item.advertiser.type)) {
      optimizely?.onReady().then(() => {
        optimizely.track('pro_leads_web');
        optimizely.track('show_phone_number_web');
      });
    }
  };

  const handleMobileClick = async (): Promise<void> => {
    trackMakeAnOfferShowPhoneNumber();
    // keep this for GTM
    window.dispatchEvent(
      new CustomEvent('subito:PhoneCall', { detail: search })
    );
    phoneContactEvent({
      item,
      search,
      type: 'Call',
      name: pulseEvent.name,
      pageType: pulseEvent.pageType,
    });
    phoneCallGTMEvent();

    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
      return;
    }

    const { contactInfo } = await clothoClient.getContactInfo(adUrn);

    if (contactInfo?.phoneNumber) {
      setPhoneNumber(contactInfo.phoneNumber);

      //eslint-disable-next-line
      window.location.href = `tel:${contactInfo.phoneNumber}`;
    }
  };

  const dispatchShowPhoneContactEvent = useCallback(
    (phoneNumber: string, adUrn: string) => {
      // keep this for GTM
      window.dispatchEvent(
        new CustomEvent('subito:ShowPhoneContact', {
          detail: {
            phoneNumber,
            urn: adUrn,
            search,
          },
        })
      );
      showPhoneContactGTMEvent();

      // Pulse event
      phoneContactEvent({
        item,
        search,
        type: 'Show',
        name: pulseEvent.name,
        pageType: pulseEvent.pageType,
      });
    },
    [search, item, pulseEvent.name, pulseEvent.pageType]
  );

  const handleDesktopClick = async (): Promise<void> => {
    trackMakeAnOfferShowPhoneNumber();

    if (phoneNumber) {
      dispatchShowPhoneContactEvent(phoneNumber, adUrn);
      setDialogOpened(true);
      return;
    }

    const { contactInfo, status } = await clothoClient.getContactInfo(adUrn);

    if (contactInfo?.phoneNumber && status === HTTPStatusCode.OK) {
      dispatchShowPhoneContactEvent(contactInfo.phoneNumber, contactInfo.adURN);
      setPhoneNumber(contactInfo.phoneNumber);
      setDialogOpened(true);
    }
  };

  return {
    phoneNumber,
    dialogOpened,
    handleMobileClick,
    handleDesktopClick,
    closeDialog: () => setDialogOpened(false),
  };
};
