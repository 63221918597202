import {
  ShortConfiguration,
  AdSenseVariations,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-bottom-addetail-tutti',
  Jobs = 'desktop-bottom-addetail-jobs',
  Motor = 'desktop-bottom-addetail-motor',
  Market = 'desktop-bottom-addetail-market',
  RealEstate = 'desktop-bottom-addetail-realestate',
}

const desktopBottomDefault: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '9281156414',
    channel: 'DESKTOP-BOTTOM-ADP-TUTTI-0-LIGHT-NO',
  },
  adBlockOptions: { number: 5 },
};

const desktopBottomJob: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '1290860420',
    channel: 'DESKTOP-BOTTOM-ADP-JOBS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 5 },
};

const desktopBottomMotor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '2659843811',
    channel: 'DESKTOP-BOTTOM-ADP-MOTORS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 5 },
};

const desktopBottomMarket: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '9281156414',
    channel: 'DESKTOP-BOTTOM-ADP-MARKET-0-LIGHT-NO',
  },
  adBlockOptions: { number: 5 },
};

const desktopBottomRealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '1594238717',
    channel: 'DESKTOP-BOTTOM-ADP-REALESTATE-0-LIGHT-NO',
  },
  adBlockOptions: { number: 5 },
};

const desktopBottom: Array<ShortConfiguration> = [
  desktopBottomDefault,
  desktopBottomJob,
  desktopBottomMotor,
  desktopBottomMarket,
  desktopBottomRealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { desktopBottom, IDs as DesktopBottomIDs, variations as DesktopBottom };
