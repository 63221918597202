import { useCallback, useState } from 'react';
import { validateMessage } from '../utils';

export function useMessageValidation() {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const isValid = useCallback((message: string) => {
    const error = validateMessage(message);

    setErrorMessage(error);
    return !error;
  }, []);

  return { isValid, errorMessage };
}
