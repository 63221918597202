import FavoritesContainer from '@client/components/Search/Containers/FavoritesContainer';
import { FavoritesConsumer } from '@sbt-web/favorites';
import { useViewport } from '@sbt-web/hooks';
import { AdItem, FeatureURI } from '@sbt-web/networking';
import { ShareComponent } from '@sbt-web/share-button';
import { Badge } from '@sbt-web/ui';
import { AD_SHARING_TITLE } from '@shared/constants';
import React from 'react';
import {
  onEngage,
  onSave,
  onUnsave,
} from '@shared/helpers/Favorites/trackingEvents';

import classes from './SocialBar.module.scss';

interface Props {
  ad: AdItem;
  onSaveFavorite?: () => void;
  onUnsaveFavorite?: () => void;
  subject?: string;
}

const SocialBar: React.FunctionComponent<Props> = ({
  ad,
  onSaveFavorite,
  onUnsaveFavorite,
}) => {
  const { category, urn, features } = ad;

  const itemSold = features[FeatureURI.TransactionStatus] != undefined;

  const { isDesktop } = useViewport();

  const handleOnSave = () => {
    onSaveFavorite?.();
    onSave(urn);
  };

  const handleOnUnsave = () => {
    onUnsaveFavorite?.();
    onUnsave(urn);
  };

  const handleOnEngage = () => {
    onEngage(urn);
  };

  return (
    <div className={classes['action-bar']}>
      <Badge classes={[classes['label-category']]}>{category.label}</Badge>
      {itemSold ? null : (
        <div className={classes.buttons}>
          <FavoritesContainer
            onSave={handleOnSave}
            onUnsave={handleOnUnsave}
            onEngage={handleOnEngage}
          >
            <ShareComponent
              design="text"
              className={classes['share-button']}
              adTrackInfo={{
                urn,
                category,
                trackIds: {
                  shareButton: () =>
                    isDesktop
                      ? 'sharing-icon-near-ad'
                      : 'sharing-icon-under-ad',
                  closeModal: () =>
                    isDesktop
                      ? 'close-sharing-near-ad-modal'
                      : 'close-sharing-under-ad-modal',
                  shareWithNativeApi: 'sharing-icon-under-ad-customsocial',
                  messenger: 'sharing-icon-near-ad-mess',
                  facebook: 'sharing-icon-near-ad-facebook',
                  whatsApp: 'sharing-icon-near-ad-whatsapp',
                  copyUrl: 'sharing-icon-near-ad-copylink',
                },
              }}
              sharingTitle={AD_SHARING_TITLE}
              sharingUrl={() => {
                const canonicalLink: HTMLLinkElement | null =
                  document.querySelector("link[rel='canonical']");
                const link = canonicalLink?.href ?? window.location.href;
                return `${link}?utm_medium=referral&utm_source=subito&utm_campaign=free-engagement-ad_detail`;
              }}
            />
            <FavoritesConsumer urn={urn} />
          </FavoritesContainer>
        </div>
      )}
    </div>
  );
};

export default SocialBar;
