import React from 'react';
import { Button } from '@sbt-web/ui';
import classnames from 'classnames';

import styles from '../index.module.scss';

export interface BuyNowButtonProps {
  onClick: () => void;
  classes?: string[];
}

export const BuyNowButton = ({ onClick, classes }: BuyNowButtonProps) => {
  return (
    <Button
      classes={[classnames(classes, styles['buy-now-button'])]}
      size="large"
      onClick={onClick}
      design="solid"
    >
      Acquista
    </Button>
  );
};
