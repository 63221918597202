import { buildAdDetailTarget, getAdId } from '@sbt-web/tracking';
import type { AdItem } from '@sbt-web/networking';
import { getOrCreatePulse } from '@tools/tracking/utils';

export const shopLinkClick = (item: AdItem): void => {
  const adId = getAdId(item.urn);
  const { object: ClassifiedAd } = buildAdDetailTarget(item);

  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      type: 'UIElement',
      id: 'click-on-shop-website-link',
    },
    target: {
      ...ClassifiedAd,
      id: adId,
    },
  });
};
