import React from 'react';
import { PayPalScriptProvider, PayPalMessages } from '@paypal/react-paypal-js';

type PayPalMessageProps = { amount: number };

const sdkInitOptions = {
  clientId:
    'AUpAvpKrczpE81Qny4K1I0NqWgZXj1b1iLsjqAJMBBweHW3mISiwIoersCV266MPBHxsB-Ch4DyP5lVo',
  currency: 'EUR',
  components: 'messages',
};

export const PayPalMessage = ({ amount }: PayPalMessageProps) => (
  <PayPalScriptProvider options={sdkInitOptions}>
    <PayPalMessages
      style={{
        layout: 'text',
        text: { size: 14 },
        logo: { type: 'alternative' },
      }}
      amount={amount}
    />
  </PayPalScriptProvider>
);
