import { useIsVisible } from '@sbt-web/hooks';
import type { ItemMap } from '@sbt-web/networking';
import { Divider, Headline6 } from '@sbt-web/ui';
import { urnTransformer } from '@sbt-web/utils';
import { transformCategory } from '@tools/tracking/transformer';
import { getDataLayer, getOrCreatePulse } from '@tools/tracking/utils';
import React from 'react';
import classes from './Map.module.scss';

interface Props {
  map?: ItemMap;
}

const observerOptionsTop = {
  threshold: 0.01,
};
const observerOptionsMiddle = {
  threshold: 0.5,
};

const buildTrackingData = (position: string) => {
  const rawAd = getDataLayer().ads[0];
  const { adId, contentId } = urnTransformer(rawAd.urn) ?? {};
  return {
    type: 'View',
    intent: 'View',
    object: {
      id: 'google_map',
      type: 'UIElement',
      elementType: 'Map',
      elementSubtype: 'google',
      position,
    },
    target: {
      id: adId,
      type: 'ClassifiedAd',
      category: transformCategory(rawAd.category),
      name: document.title,
      adId: adId && Number.parseInt(adId),
      contentId,
    },
    page: {
      pageType: 'ad_detail',
      '@id': 'sdrn:subito:page:ad_detail',
      '@type': 'Page',
    },
  };
};

export function Map({ map }: Props) {
  const element = React.useRef(null);
  const [isVisibleTop, setElementTop, unobserveTop] = useIsVisible({
    observerOptions: observerOptionsTop,
  });
  const [isVisibleMiddle, setElementMiddle, unobserveMiddle] = useIsVisible({
    observerOptions: observerOptionsMiddle,
  });

  React.useEffect(() => {
    if (element.current) {
      setElementTop(element.current);
    }
    if (isVisibleTop) {
      getOrCreatePulse()?.queueEvent(buildTrackingData('Top'));
      unobserveTop();
    }
  }, [isVisibleTop, setElementTop, unobserveTop]);

  React.useEffect(() => {
    if (element.current) {
      setElementMiddle(element.current);
    }
    if (isVisibleMiddle) {
      getOrCreatePulse()?.queueEvent(buildTrackingData('Middle'));
      unobserveMiddle();
    }
  }, [isVisibleMiddle, setElementMiddle, unobserveMiddle]);

  if (!map?.longitude || !map?.latitude) {
    return null;
  }
  return (
    <>
      <Divider topSpacing="sm" bottomSpacing="xxl" />

      <Headline6>Posizione</Headline6>

      <div ref={element}>
        <iframe
          title="Mappa posizione annuncio"
          className={classes.frame}
          loading="lazy"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.NEXT_PUBLIC_GCP_KEY}&q=${map.latitude},${map.longitude}&zoom=14`}
          allowFullScreen
        />
      </div>
    </>
  );
}
