import classnames from 'classnames';
import React from 'react';
import Gallery, { Props as GalleryProps } from '../../Gallery';
import type { SectionProps } from './section';

type Props = SectionProps & GalleryProps;

const GallerySection: React.FC<Props> = ({ ad, classes = [] }) => (
  <section className={classnames(...classes)}>
    <Gallery ad={ad} />
  </section>
);

export default GallerySection;
