import React from 'react';
import classes from './index.module.scss';

type logoProps = { logoURL?: string };

export const ShopLogo = ({ logoURL }: logoProps) => (
  <div className={classes.imageFrame}>
    {logoURL ? (
      <img src={logoURL} alt="" />
    ) : (
      <div className={classes.noImage}>
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M34.77 15.5c0 1.657 1.377 3 3.076 3 1.654 0 3.004-1.273 3.074-2.869L38.02 9H9.981L7.08 15.631c.07 1.596 1.42 2.869 3.074 2.869 1.7 0 3.077-1.343 3.077-3 0-.828.689-1.5 1.538-1.5.85 0 1.539.672 1.539 1.5 0 1.657 1.377 3 3.077 3 1.699 0 3.077-1.343 3.077-3 0-.828.688-1.5 1.538-1.5.85 0 1.538.672 1.538 1.5 0 1.657 1.378 3 3.077 3 1.7 0 3.077-1.343 3.077-3 0-.828.69-1.5 1.539-1.5.85 0 1.538.672 1.538 1.5zM12 21.225V39h4.465c-.007-7.412 2.452-11.5 7.535-11.5s7.542 4.088 7.535 11.5H36V21.225a6.175 6.175 0 0 1-2.77-1.756 6.208 6.208 0 0 1-4.615 2.031A6.208 6.208 0 0 1 24 19.469a6.208 6.208 0 0 1-4.615 2.031 6.208 6.208 0 0 1-4.616-2.031A6.175 6.175 0 0 1 12 21.225zm-3 .17c-2.835-.525-4.981-2.945-5-5.857-.005-.213.035-.431.126-.64l3.238-7.4A2.5 2.5 0 0 1 9.654 6h28.692a2.5 2.5 0 0 1 2.29 1.498l3.238 7.4c.091.209.131.427.126.64-.019 2.912-2.165 5.332-5 5.857V39.5a2.5 2.5 0 0 1-2.5 2.5h-6.292l-.065-.001a1.7 1.7 0 0 1-1.633-1.764c.26-6.793-1.306-9.735-4.51-9.735-3.204 0-4.77 2.942-4.51 9.735l.002.065a1.7 1.7 0 0 1-1.7 1.7H11.5A2.5 2.5 0 0 1 9 39.5V21.395z"
            fill="#AEB9C6"
            fillRule="evenodd"
          />
        </svg>
      </div>
    )}
  </div>
);
