import { BodyText } from '@sbt-web/ui';
import classnames from 'classnames';
import React from 'react';
import ownClasses from './feature-list.module.scss';

export interface Feature {
  uri?: string;
  label: string | undefined;
  value: string;
}

type FeatureProp = Omit<Feature, 'uri'>;

export interface FeatureListProps {
  features: FeatureProp[];
  singleColumn?: boolean;
}

export const FeatureList: React.FunctionComponent<FeatureListProps> = ({
  features = [],
  singleColumn = false,
}) => {
  return features.length > 0 ? (
    <ul
      className={classnames(ownClasses['feature-list'], {
        [ownClasses.list]: singleColumn,
        [ownClasses.columns]: !singleColumn,
      })}
    >
      {features.map((f) => (
        <FeatureListItem key={f.label} label={f.label} value={f.value} />
      ))}
    </ul>
  ) : null;
};

export const FeatureListItem: React.FunctionComponent<FeatureProp> = ({
  label,
  value,
}) => (
  <li className={classnames(ownClasses.feature)}>
    <BodyText classes={[ownClasses.label]} element="span">
      {label}
    </BodyText>

    <BodyText classes={[ownClasses.value]} element="span">
      {value}
    </BodyText>
  </li>
);

export default FeatureList;
