import React from 'react';

import Labels from '../../Labels';
import { Caption, Headline5, Icon, IconName, IconSize } from '@sbt-web/ui';
import classes from './index.module.scss';

export function FormSuccess() {
  return (
    <div className={classes['abuse-success-wrapper']}>
      <Icon
        name={IconName.CheckCircle}
        iconSize={IconSize.LG}
        classes={[classes['icon-check']]}
      />
      <Headline5>{Labels.FORM.success.title}</Headline5>
      <Caption weight="book">{Labels.FORM.success.details}</Caption>
    </div>
  );
}
