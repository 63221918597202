import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { LoanProps } from '@client/components/Detail/Skyscraper';
import { CategoryId } from '@sbt-web/networking';
import type { AdItem } from '@sbt-web/networking';

interface ShouldRenderPrestiPayBoxProps {
  item: AdItem;
  isDesktop?: boolean;
}

// Create a category-to-price map to determine eligibility for displaying the PrestiPayBox
const categoryPriceMapDesktop: Map<CategoryId, number> = new Map([
  [CategoryId.Appartamenti, 0],
  [CategoryId.Ville, 0],
  [CategoryId.LoftMansarde, 0],
  [CategoryId.GarageBox, 0],
]);

const categoryPriceMap: Map<CategoryId, number> = new Map([
  [CategoryId.AccessoriAuto, 2000],
  [CategoryId.AccessoriMoto, 2000],
  [CategoryId.ArredamentoCasalinghi, 2000],
  [CategoryId.Elettrodomestici, 2000],
  [CategoryId.Biciclette, 2000],
  [CategoryId.Informatica, 2000],
  [CategoryId.Fotografia, 2000],
]);

/**
 * Determines whether the PrestiPayBox should be rendered based on ad category and price.
 */
const shouldRenderPrestiPayBox = ({
  item,
  isDesktop = false,
}: ShouldRenderPrestiPayBoxProps): boolean => {
  if (item.type.key !== 's') {
    return false;
  }
  const mapPrice =
    categoryPriceMap.get(item.category.id) ??
    (isDesktop ? categoryPriceMapDesktop.get(item.category.id) : undefined);
  const priceValue = Number(item.features['/price']?.values?.[0]?.key) || 0;

  // Ensure the map has the category and the price condition is met
  if (mapPrice !== undefined) {
    return priceValue >= mapPrice;
  }

  return false;
};

// Dynamically import PrestiPayBox component for better code splitting and performance
const PrestiPayBox = dynamic<LoanProps>(
  () =>
    import('@sbt-web/ancillary-services').then((module) => module.PrestiPayBox),
  { ssr: false } // Optionally disable server-side rendering if not needed
);
PrestiPayBox.displayName = 'DynamicPrestiPayBox';

export const LazyPrestiPayBox: React.FC<
  ShouldRenderPrestiPayBoxProps & LoanProps
> = ({ item, isDesktop, ...restComponentProps }) => {
  const shouldRender = useMemo(
    () => shouldRenderPrestiPayBox({ item, isDesktop }),
    [isDesktop, item]
  );

  return shouldRender ? (
    <PrestiPayBox {...restComponentProps} item={item} />
  ) : null;
};
