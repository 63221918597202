import { ItemMap } from '@sbt-web/networking';
import classnames from 'classnames';
import React from 'react';
import YatmoMap from '../../YatmoMap';
import type { SectionProps } from './section';

interface OwnProps {
  map?: ItemMap;
}

type Props = OwnProps & SectionProps;

function YatmoMapSection({ classes = [], map }: Props) {
  return (
    <section className={classnames(...classes)}>
      <YatmoMap map={map} />
    </section>
  );
}

export default YatmoMapSection;
