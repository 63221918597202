import { AdTypes, CategoryStore } from '@sbt-web/networking';
import { AdItem, FeatureURI } from '@sbt-web/networking';
import { CategoryId } from '@sbt-web/networking';
import { evalBoolFeature } from '@shared/models';

export enum Copy {
  NoAgenciesNoTlmk = 'Non voglio essere contattato da agenzie o telemarketing',
  JobOfferDisclaimer = 'Il presente annuncio è rivolto ad entrambi i sessi, ai sensi delle leggi 903/77 e 125/91, e a persone di tutte le età e tutte le nazionalità, ai sensi dei decreti legislativi 215/03 e 216/03.',
  OriginalItem = 'Il venditore dichiara che il bene oggetto del presente annuncio è originale.',
}

const originalItemCategories = [
  CategoryId.AbbigliamentoAccessori,
  CategoryId.Sports,
  CategoryId.TuttoBambini,
];

export const getCopy = (item: AdItem): string | null => {
  if (!item.category) {
    return null;
  }

  const categoryId = item.category.id;
  if (categoryId === CategoryId.OfferteLavoro) {
    return Copy.JobOfferDisclaimer;
  }

  if (
    originalItemCategories.includes(categoryId) &&
    // We do not show this disclaimer for Cercasi ads
    item.type.key !== AdTypes.Seek
  ) {
    return Copy.OriginalItem;
  }

  const macro = CategoryStore.getMacroByCategoryId(categoryId);
  if (macro?.id === CategoryId.Immobili) {
    const noSalesmanFeature = item.features[FeatureURI.NoSalesman];
    if (evalBoolFeature(noSalesmanFeature)) {
      return Copy.NoAgenciesNoTlmk;
    }
  }

  return null;
};
