import { Caption, Headline6 } from '@sbt-web/ui';
import classnames from 'classnames';
import React, { type FunctionComponent, type PropsWithChildren } from 'react';
import BrandVideo, { DialogProps } from './BrandVideo';
import css from './style/index.module.scss';

interface LinkProps {
  url: string;
}

/* eslint-disable react/jsx-no-target-blank */
// We want to send referrer headers for now
// We also want to have nofollow on the link, as advised by the SEO team
const BrandLink: FunctionComponent<PropsWithChildren<LinkProps>> = ({
  url,
  children,
}) => (
  <a
    href={url}
    target="_blank"
    rel="noopener nofollow external"
    className={classnames(css['brand-seal'], css['quality-seal-link'])}
  >
    {children}
  </a>
);
/* eslint-enable react/jsx-no-target-blank */

const SealWrapper: FunctionComponent<PropsWithChildren<DialogProps>> = ({
  videoId,
  url,
  name,
  children,
}) => {
  if (videoId) {
    return (
      <BrandVideo
        name={name}
        url={url}
        videoId={videoId}
        classes={[css['brand-seal']]}
      >
        {children}
      </BrandVideo>
    );
  } else {
    return <BrandLink url={url}>{children}</BrandLink>;
  }
};

const Logo: React.FunctionComponent<{
  name: string;
  logo?: string;
}> = ({ name, logo }) => {
  if (logo) {
    return (
      <img
        src={`/static/img/quality-seal/${logo}`}
        alt={name}
        decoding="async"
        width="140"
        height="32"
      />
    );
  } else {
    return <p className={css['quality-seal-name']}>{name}</p>;
  }
};

interface Props {
  qualitySeal: {
    id: string;
    name: string;
    brand: string;
    external_url: string;
    youtube_id?: string;
    logoName?: string;
  };
}

const QualitySeal = ({ qualitySeal }: Props) => (
  <>
    <Headline6 classes={[css['quality-seal-heading']]}>
      Incluso nel prezzo
    </Headline6>

    <figure className={css['quality-seal']}>
      <figcaption className={css['quality-seal-caption']}>
        <img
          className={css['check-icon']}
          src="/static/img/quality-seal/check-circle.svg"
          width="24"
          height="24"
          alt=""
          loading="lazy"
          decoding="async"
        />
        <Caption
          classes={[css['quality-seal-caption-text']]}
          size="small"
          element="span"
        >
          Certificato
          <br />
          di qualità
        </Caption>
      </figcaption>

      <SealWrapper
        videoId={qualitySeal['youtube_id']}
        url={qualitySeal['external_url']}
        name={qualitySeal.name}
      >
        <Logo name={qualitySeal.name} logo={qualitySeal.logoName} />
      </SealWrapper>
    </figure>
  </>
);

export default QualitySeal;
