import { type AdItem, AdvertiserType } from '@sbt-web/networking';

const SEO_DESCRIPTION_MAX_LENGTH = 250;

const getTitle = (item: AdItem, advertiserName: string): string => {
  let title = '';

  if (item.advertiser.type === AdvertiserType.ImpresaPiu) {
    title += `Subito - ${advertiserName} - `;
  }

  title += `${item.subject} - ${item.category.label} ${item.type.value}`;

  if (item.geo.city) {
    title += ` a ${item.geo.city.value}`;
  }

  return title;
};

const getDescription = (item: AdItem): string =>
  item.body.slice(0, SEO_DESCRIPTION_MAX_LENGTH).replace(/\n/g, ' ');

export { getDescription, getTitle };
