import type { AdItem } from '@sbt-web/networking';
import classnames from 'classnames';
import React from 'react';
import Skyscraper from '../../Skyscraper';
import type { SectionProps } from './section';

type Props = SectionProps & {
  item: AdItem;
  newCreditAgricoleToggle?: boolean;
};

const SkyscraperSection: React.FC<Props> = ({
  item,
  classes = [],
  newCreditAgricoleToggle,
}) => (
  <aside className={classnames(...classes)}>
    <Skyscraper item={item} newCreditAgricoleToggle={newCreditAgricoleToggle} />
  </aside>
);
export default SkyscraperSection;
