import { AdsAdDetailsBlock } from '@client/components/Adv/AdSense/adDetails/AdDetailsBlock';
import { AdvDetailContext } from '@client/components/Adv/AdvAdDetailContext';
import {
  AdsGAMPlacement,
  DesktopAdDetailGAMIds,
  MsiteAdDetailGAMIds,
} from '@client/components/Adv/GPT';
import { useViewport } from '@sbt-web/hooks';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { AdItem, AdvertiserType } from '@sbt-web/networking';
import { RelatedAdsContainer } from '@sbt-web/related-ads';
import { Divider } from '@sbt-web/ui';
import { formatMainData } from '@sbt-web/utils';
import flattenFeatures from '@shared/helpers/FlattenFeatures';
import { isPro } from '@shared/models/Advertiser';
import { getOrCreatePulse } from '@tools/tracking/utils';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import InternalLinksFooter from '../../SEO/InternalLinks/Footer';
import InternalLinksHeader from '../../SEO/InternalLinks/Header';
import { MakeYourPrice } from '../AdReply/components/MakeYourPrice';
import NavigationRow from '../NavigationRow';
import qualitySeals from '../QualitySeal/quality-seals';
import { LoanProps, getLoanBoxTitle } from '../Skyscraper';
import StickyBar from '../StickyBar';
import { searchForRecommendation } from './../../../../server/detail-requests/item';
import {
  AdvertiserInfoSection,
  BottomSection,
  DescriptionSection,
  GallerySection,
  GeneralInfoSection,
  InfocarSection,
  MainDataSection,
  MapSection,
  QualitySealSection,
  SkyscraperSection,
  VehicleHistorySection,
} from './Layout';
import type { InfocarProps } from './Layout/InfocarSection';
import { trackAutoClick, trackImpression } from './Layout/RelatedAds';
import type { Props as CommonProps } from './common-props';
import css from './style/container.module.scss';
import gridStyles from './style/grid.module.scss';
import { removeInfoCarDuplication } from '@shared/helpers/RemoveInfoCarDuplication';

export type Props = CommonProps & InfocarProps;

const defaultDesktopRightBlockRowSpan = 6;

const AgosBox = dynamic<LoanProps>(() =>
  import('@sbt-web/ancillary-services').then((module) => module.AgosBox)
);

export function AutoContainer({
  ad,
  infocarData = null,
  internalLinks,
  shop,
  favoriteCounter,
  advertiserProfile,
  trustInfo,
}: Props) {
  const { isMobile, isDesktop } = useViewport();

  const mapDetails = ad.geo.map;

  const hasMap = mapDetails?.longitude != null && mapDetails?.latitude != null;

  const qualitySealId: string | null =
    ad.features['/quality_seal']?.values?.[0]?.key;

  const hasQualitySeal = qualitySealId != null && qualitySealId in qualitySeals;

  /**
   * The 6 main features of the car (the same shown on the item card)
   */
  const mainFeatures = formatMainData(ad, {
    short: false,
    assetsBase: process.env.NEXT_PUBLIC_ASSETS_BASE_URL,
  });
  const hasMainFeatures = mainFeatures.length > 0;

  /**
   * All features of the car
   */
  const features = flattenFeatures(ad.features, ad.geo);
  const hasFeatures = features.length > 0;

  const [desktopRightBlockRowSpan, setDesktopRightBlockRowSpan] = useState(
    () => {
      let result = defaultDesktopRightBlockRowSpan;

      // Main Data section
      if (hasMainFeatures) {
        result += 1;
      }

      // Vehicle history section
      if (hasFeatures) {
        result += 2;
      }

      if (hasQualitySeal) {
        result += 1;
      }

      if (infocarData) {
        result += 2;
      }

      if (hasMap) {
        result += 1;
      }

      return result;
    }
  );
  const [recommendedAds, setRecommendedAds] = useState<AdItem[]>([]);
  const { optimizely } = useContext(OptimizelySubitoContext);

  const increaseRowSpan = useCallback(
    () => setDesktopRightBlockRowSpan((span) => span + 1),
    []
  );

  const handleOnVisible = useCallback(() => {
    increaseRowSpan();
    trackImpression(
      ad,
      recommendedAds,
      'Engagement',
      'Recommendation widget impression',
      {
        transactionId: 'sdrn:subito:recommendation:subito-suggested-ads',
        itemId: ad.urn,
        listName: 'relateditems:subitov0',
      }
    );
  }, [ad, increaseRowSpan, recommendedAds]);

  useEffect(() => {
    const price = Number.parseInt(ad.features['/price']?.values[0]?.key ?? '0');

    const request = isPro(ad.advertiser.type)
      ? searchForRecommendation({
          pe: (price + 0.2 * price).toFixed(),
          ps: (price - 0.2 * price).toFixed(),
          uid: ad.advertiser.userId,
        })
      : searchForRecommendation({
          cb: ad.features['/car']?.values[0]?.key?.toString(),
          cmo: ad.features['/car']?.values[1]?.key?.toString(),
          pe: (price + 0.2 * price).toFixed(),
          ps: (price - 0.2 * price).toFixed(),
          r: ad.geo.region.id,
        });

    request.then((searchResult) => {
      const results = searchResult.filter((item) => item.urn !== ad.urn);
      setRecommendedAds(results);
      trackImpression(ad, results, 'View', 'Recommendation widget impression', {
        transactionId: 'sdrn:subito:recommendation:subito-suggested-ads',
        itemId: ad.urn,
        listName: 'relateditems:subitov0',
      });
    });
  }, [ad]);

  const [makeAnOfferABTestVariant, setMakeAnOfferABTestVariant] = useState<
    'A' | 'B'
  >('A');

  useEffect(() => {
    if (ad.advertiser.type === AdvertiserType.ImpresaPiu) {
      optimizely?.onReady().then(() => {
        setMakeAnOfferABTestVariant(
          (optimizely?.activate('subito-ad-detail-make-pro-proposal-flow') ??
            'A') as 'A' | 'B'
        );
      });
    }
  }, [optimizely, ad.advertiser.type]);

  const advContext = useContext(AdvDetailContext);
  const { showBannerBelowGallery } = advContext;

  const infoCarNoDuplication = removeInfoCarDuplication(infocarData, features);

  return (
    <>
      {internalLinks != null ? (
        <div
          className={classnames(
            gridStyles['detail-component'],
            gridStyles['navigation-detail-container']
          )}
        >
          <InternalLinksHeader links={internalLinks.header} />
        </div>
      ) : null}
      <div className={css['outer-ad-container']}>
        <MakeYourPrice item={ad} />
        <div
          className={classnames(
            css['inner-ad-container'],
            gridStyles['detail-container']
          )}
          style={{ '--right-block-row-span': desktopRightBlockRowSpan }}
        >
          {/* Not counted in desktop row span: it's above the right-side's block */}
          <section
            className={classnames([
              gridStyles['detail-component'],
              gridStyles['navigation-detail-container'],
            ])}
          >
            <NavigationRow item={ad} />
          </section>

          {/* 1 desktop row */}
          <GallerySection
            classes={[gridStyles['detail-component'], gridStyles.gallery]}
            ad={ad}
          />

          {isMobile && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={MsiteAdDetailGAMIds.ANCHOR}
              fixedHeight={50}
            />
          )}

          {/* This is the right-side container */}
          <div
            className={classnames(
              gridStyles['detail-component'],
              gridStyles['right-container']
            )}
          >
            <GeneralInfoSection
              classes={[
                gridStyles['ad-info-container'],
                gridStyles['ad-info-override'],
              ]}
              ad={ad}
              promo={null}
              shop={shop}
              favoriteCounter={favoriteCounter}
              advertiserProfile={advertiserProfile}
              trustInfo={trustInfo}
              shippingCosts={null}
            />
            {isDesktop ? (
              <SkyscraperSection item={ad} classes={[gridStyles.skyscraper]} />
            ) : null}
          </div>

          {showBannerBelowGallery && (
            <AdsGAMPlacement
              classes={[gridStyles['detail-component']]}
              id={DesktopAdDetailGAMIds.BELOW_GALLERY}
            />
          )}

          {hasMainFeatures ? (
            <MainDataSection
              classes={[gridStyles['detail-component']]}
              features={mainFeatures}
            />
          ) : null}

          {hasQualitySeal ? (
            <QualitySealSection
              classes={[gridStyles['detail-component']]}
              qualitySealId={qualitySealId}
            />
          ) : null}

          {/* 2 desktop rows */}
          <DescriptionSection
            ad={ad}
            classes={[gridStyles['detail-component'], gridStyles.description]}
          />

          {/* 3 desktop rows */}
          <Divider
            spacing="null"
            classes={[
              gridStyles['detail-component'],
              gridStyles['section-divider'],
            ]}
          />

          {isDesktop ? null : (
            <>
              <AdsGAMPlacement
                classes={[gridStyles['detail-component']]}
                id={MsiteAdDetailGAMIds.BOX1}
                fixedHeight={250}
              />
              <Divider
                spacing="null"
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['section-divider'],
                ]}
              />
            </>
          )}

          {hasFeatures ? (
            <VehicleHistorySection
              features={features}
              adItem={ad}
              classes={[
                gridStyles['detail-component'],
                gridStyles['vehicle-history'],
              ]}
            />
          ) : null}

          {infoCarNoDuplication != null && (
            <>
              <Divider
                spacing="null"
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['section-divider'],
                ]}
              />

              <InfocarSection
                classes={[
                  gridStyles['detail-component'],
                  gridStyles['infocar'],
                ]}
                infocarData={infoCarNoDuplication}
              />
            </>
          )}

          {hasMap ? (
            <MapSection
              classes={[gridStyles['detail-component'], gridStyles.map]}
              map={mapDetails}
            />
          ) : null}
          {isMobile && (
            <section className={gridStyles['detail-component']}>
              <AgosBox
                assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
                item={ad}
                price={ad.features['/price']}
                title={getLoanBoxTitle(ad.category)}
                pulseInstance={getOrCreatePulse()}
                houstonTrackAncillary={(t) => {
                  optimizely?.track(t);
                }}
              />
            </section>
          )}

          {/* 4 desktop rows */}
          <Divider
            spacing="null"
            classes={[
              gridStyles['detail-component'],
              gridStyles['section-divider'],
            ]}
          />

          {/* 5 desktop rows */}
          <AdvertiserInfoSection
            classes={[gridStyles['detail-component']]}
            item={ad}
            shop={shop}
            trustInfo={null}
            advertiserProfile={advertiserProfile}
            makeAnOfferABTestVariant={makeAnOfferABTestVariant}
          />

          <RelatedAdsContainer
            data={recommendedAds}
            className={gridStyles['detail-component']}
            // This is conditional: not a guaranteed row
            onVisible={handleOnVisible}
            onClick={(item, index) => {
              trackAutoClick(ad, item.urn, index, {
                transactionId:
                  'sdrn:subito:recommendation:subito-suggested-ads',
                itemId: ad.urn,
                listName: 'relateditems:subitov0',
              });
            }}
          />
        </div>

        {!isMobile ? (
          <>
            {/* 6 desktop rows */}
            <AdsAdDetailsBlock
              listingCategory={ad.category.id}
              className={gridStyles['detail-component']}
            />
            {/* Not a desktop row: it's rendered outside of the grid */}
            <StickyBar
              item={ad}
              makeAnOfferABTestVariant={makeAnOfferABTestVariant}
            />
          </>
        ) : null}

        {isDesktop ? null : <AdsGAMPlacement id={MsiteAdDetailGAMIds.BOX2} />}
      </div>

      <BottomSection item={ad} />

      {internalLinks != null ? (
        <InternalLinksFooter links={internalLinks.footer} />
      ) : null}

      {isDesktop ? <div id="apn_body" /> : null}
    </>
  );
}

export default AutoContainer;
