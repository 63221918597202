import { Button, Caption, FullModal, Loader, TextField } from '@sbt-web/ui';
import React, { useEffect, useRef, useState } from 'react';
import { updateNameClient } from '@sbt-web/auth';
import { getUsername, updateUsername } from '@shared/polymatheia-client';

import '@sbt-web/user-rating/dist/index.css';
import ownClasses from './index.module.scss';

export interface UsernameDialogProps {
  onCloseModal?: () => void;
}

export const UsernameDialogEvent = {
  CheckBuyerName: 'subito:UsernameDialog:CheckBuyerName',
  GetBuyerName: 'subito:UsernameDialog:GetBuyerName',
};

export enum UsernameDialogAction {
  BuyNow = 'BUY_NOW',
  SendOffer = 'SEND_OFFER',
  Contact = 'CONTACT',
}

export const UsernameDialog = ({ onCloseModal }: UsernameDialogProps) => {
  const [name, setName] = useState('');
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [opened, setOpened] = useState(false);
  const eventActionRef = useRef<UsernameDialogAction>();
  const eventUserIdRef = useRef<string>('');

  const handleDispatchEvent = (buyerName: string) => {
    window.dispatchEvent(
      new CustomEvent(UsernameDialogEvent.GetBuyerName, {
        detail: {
          buyerName,
          action: eventActionRef.current,
        },
      })
    );
  };

  const handleChekBuyerName = async (event: Event) => {
    if (!(event instanceof CustomEvent)) {
      console.error('The event is not a CustomEvent');
      return;
    }

    if (!event.detail?.userId) {
      console.error('userId of the buyer is not defined');
      return;
    }

    if (!event.detail?.action) {
      console.error('The action of the event is not defined');
      return;
    }

    eventActionRef.current = event.detail?.action;
    eventUserIdRef.current = event.detail?.userId;

    const user = await getUsername(event.detail?.userId);

    if (!user || user.isDefaultName) {
      setOpened(true);
    } else {
      handleDispatchEvent(user.username);
    }
  };

  const handleSyncChekBuyerName: EventListener = (event) => {
    handleChekBuyerName(event);
  };

  useEffect(() => {
    window.addEventListener(
      UsernameDialogEvent.CheckBuyerName,
      handleSyncChekBuyerName
    );

    return () => {
      window.removeEventListener(
        UsernameDialogEvent.CheckBuyerName,
        handleSyncChekBuyerName
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullModal
      title="Scrivi il tuo nome"
      open={opened}
      onClose={() => {
        setOpened(false);
        onCloseModal?.();
      }}
      size="small"
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoader(true);
          const error = await updateUsername(eventUserIdRef.current, name);
          if (error) {
            setErrorMsg(error);
          } else {
            setOpened(false);
            handleDispatchEvent(name);

            //Update cookie User Data
            updateNameClient(
              name,
              process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT
            );
          }
          setLoader(false);
        }}
      >
        <Loader loading={loader} />
        <Caption classes={[ownClasses.marginBottom]} weight="book">
          Te lo chiediamo una volta sola e lo ricorderemo per te: <br />
          così rispondere agli annunci sarà ancora più veloce
        </Caption>
        <TextField
          name="name"
          placeholder="Nome"
          inputHeaderProps={{ label: 'Nome' }}
          value={name}
          onInputChange={(e) => setName(e.target.value)}
          errorMessage={errorMsg}
        />
        <Caption weight="book" classes={[ownClasses.caption]}>
          Il tuo nome sarà visibile solo alle persone che contatterai
        </Caption>
        <Button design="solid" type="submit" classes={[ownClasses.btn]}>
          Ok
        </Button>
      </form>
    </FullModal>
  );
};

export default UsernameDialog;
