import React from 'react';
import { configsAdSenseMsite } from './config';
import { useIsVisible } from '@sbt-web/hooks';
import { SmartAdSenseBlock } from '@client/components/Search/Containers/SmartAdvItem';
import { MsiteBottom } from '@client/components/Adv/AdSense/adDetails/adUnits/msite-bottom';
import type { CategoryId } from '@sbt-web/networking';
import { macroFromCategoryId } from '@shared/helpers/Themes';
import { fromMacroCategoryToVertical } from '@client/components/Search/Containers/SmartAdvItem/helpers';

const observerOptions = {
  threshold: 0.01,
  rootMargin: '200px',
};

const runAdsense = function (query: string, macroCategory: string) {
  window.subitoAdvQueue.push(() => {
    const config =
      configsAdSenseMsite[
        MsiteBottom[fromMacroCategoryToVertical(macroCategory)]
      ];
    const pageOptions = { ...config.pageOptions, query };
    window.subitoAdv.adSense.runAdsense(pageOptions, [config.adBlockOptions]);
  });
};

interface Props {
  query: string;
  listingCategory: CategoryId;
}

export const AdsAdDetailsMSite = function ({ query, listingCategory }: Props) {
  const macroCategory = macroFromCategoryId(listingCategory).friendly;
  const element = React.useRef(null);
  const [isVisible, setElement, unobserve] = useIsVisible({
    observerOptions,
  });

  const runAdSenseAndDisableObserver = React.useCallback(() => {
    runAdsense(query, macroCategory);
    unobserve();
  }, [query, unobserve, macroCategory]);

  /**
   * When element is Visible run adsense and disable observer.
   */
  React.useEffect(() => {
    if (isVisible) {
      runAdSenseAndDisableObserver();
    }
  }, [isVisible, runAdSenseAndDisableObserver]);

  React.useEffect(() => {
    if (element.current) {
      setElement(element.current);
    }
  }, [setElement]);

  return (
    <SmartAdSenseBlock
      ref={element}
      listingCategory={listingCategory}
      variations={MsiteBottom}
      fallbackAdUnitId="msite-bottom-addetail"
    />
  );
};

export const AdsAdDetailsMSiteBlock = function (
  props: Props & { isMobile: boolean }
) {
  return props.isMobile ? <AdsAdDetailsMSite {...props} /> : null;
};
