import { CategoryId } from '@sbt-web/networking';
import { Caption, TextLink } from '@sbt-web/ui';
import { getOrCreatePulse } from '@tools/tracking/utils';
import React from 'react';

import classes from './index.module.scss';

interface TipProps {
  tip: TipInterface;
  adUrn: string;
}

interface TipInterface {
  icon: string;
  copy: string;
  cssClass: string;
  link: string;
  linkCopy: string;
}

const MARKET_CATEGORIES_ID = [
  CategoryId.ConsoleVideogiochi,
  CategoryId.Informatica,
  CategoryId.AudioVideo,
  CategoryId.Fotografia,
  CategoryId.Telefonia,
  CategoryId.Elettronica,
  CategoryId.CasaPersona,
  CategoryId.ArredamentoCasalinghi,
  CategoryId.GiardinoFaiDaTe,
  CategoryId.AbbigliamentoAccessori,
  CategoryId.TuttoBambini,
  CategoryId.SportsHobby,
  CategoryId.MusicaFilm,
  CategoryId.LibriRiviste,
  CategoryId.StrumentiMusicali,
  CategoryId.Sports,
  CategoryId.Biciclette,
  CategoryId.Collezionismo,
];
const CASE_VACANZA_CATEGORY_ID = CategoryId.CaseVacanza;
const ANIMALI_CATEGORY_ID = CategoryId.Animali;

const caseVacanzaTip = {
  icon: 'doc',
  copy: 'segui i nostri consigli per ',
  cssClass: 'casa-vacanze',
  link: 'https://www.subito.it/magazine/trick-or-trip.html?from=ad_detail',
  linkCopy: 'affittare una casa vacanza in sicurezza',
};

const marketTip = {
  icon: 'lucchetto',
  copy: 'ecco come fare per evitare le truffe. ',
  cssClass: 'market',
  link: 'https://assistenza.subito.it/hc/it/articles/360001165545-I-consigli-sicuri-di-Subito-con-Polizia-Postale?from=ad_detail',
  linkCopy: 'Acquista in sicurezza',
};

const animaliTip = {
  icon: 'animale',
  copy: 'scopri come evitare le truffe. ',
  cssClass: 'market',
  link: 'https://assistenza.subito.it/hc/it/articles/360016431617-Animali?from=ad_detail',
  linkCopy: 'Leggi i nostri consigli',
};

const SafetyTips = ({
  category,
  adUrn,
}: {
  category: CategoryId;
  adUrn: string;
}) => {
  if (category === CASE_VACANZA_CATEGORY_ID) {
    return <SafetyTip tip={caseVacanzaTip} adUrn={adUrn} />;
  }

  if (category === ANIMALI_CATEGORY_ID) {
    return <SafetyTip tip={animaliTip} adUrn={adUrn} />;
  }

  if (MARKET_CATEGORIES_ID.some((cat) => cat === category)) {
    return <SafetyTip tip={marketTip} adUrn={adUrn} />;
  }

  return null;
};

const trackingClick = (adUrn: string): void => {
  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:classified:${adUrn}:element:click-on-safety-tip`,
    },
  });
};

const SafetyTip = ({ adUrn, tip }: TipProps) => (
  <aside className={classes['safety-tips-wrapper']}>
    <div className={classes['safety-tips-icon']}>
      <img
        src={`/static/img/pillole-di-sicurezza/${tip.icon}.svg`}
        alt=""
        width={32}
        height={32}
      />
    </div>
    <p>
      <Caption element="span">Fai attenzione: </Caption>
      <Caption element="span" weight="book">
        {tip.copy}
      </Caption>

      <TextLink
        size="sm"
        href={tip.link}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          trackingClick(adUrn);
        }}
      >
        <Caption element="span" weight="book">
          {tip.linkCopy}
        </Caption>
      </TextLink>
    </p>
  </aside>
);

export default SafetyTips;
