import { DataLayerBridge } from '@client/bridges/DataLayer';
import { useAdsenseAdDetails } from '@client/components/Adv/AdSense/adDetails/useAdsenseAdDetails';
import { type AdvDetailContextType } from '@client/components/Adv/AdvAdDetailContext';
import { useAdvDetails } from '@client/components/Adv/GPT';
import { usePolarisSkyscrapers } from '@client/components/Adv/GPT/polarisSkyscrapers/usePolarisSkyscrapers';
import {
  markPerformance,
  Marks,
} from '@client/utilities/performance/performanceMonitor';
import { initPresencePing } from '@client/utilities/presence-ping';
import { initDidomi } from '@sbt-web/didomi';
import { useViewport } from '@sbt-web/hooks';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import type { AdItem } from '@sbt-web/networking';
import { queueTask } from '@sbt-web/utils';
import { sendViewClassifiedAdEvent } from '@tools/tracking/AdViewTracking';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { useContext, useEffect, useState } from 'react';

type Params = {
  ad: AdItem;
  trustPingEnabled?: boolean;
};

// This toggle handles the placements inside the gallery
const GALLERY_PLACEMENT_TOGGLE = 'subito.web.adv.detail-gallery';
// This toggle handles the placement below the gallery (for desktop)
const DESKTOP_BELOW_GALLERY_PLACEMENT_TOGGLE = 'subito.web.adv.below-gallery';

const useAdDetailCommons = ({
  ad,
  trustPingEnabled,
}: Params): AdvDetailContextType => {
  const { isDesktop } = useViewport();
  const [clientReady, setClientReady] = useState<boolean>(false);
  const [advDetailGalleryToggle, setAdvDetailGalleryToggle] = useState(false);
  const [showBannerBelowGallery, setShowBannerBelowGallery] = useState(false);

  const { optimizely } = useContext(OptimizelySubitoContext);
  const skyscraperData = usePolarisSkyscrapers();

  useEffect(() => {
    optimizely?.onReady().then(() => {
      setAdvDetailGalleryToggle(
        optimizely.isFeatureEnabled(GALLERY_PLACEMENT_TOGGLE)
      );
      if (isDesktop) {
        setShowBannerBelowGallery(
          optimizely.isFeatureEnabled(DESKTOP_BELOW_GALLERY_PLACEMENT_TOGGLE)
        );
      }

      setClientReady(true);
    });
  }, [optimizely, isDesktop]);

  const { setDataLayerReadyForAdv } = useAdvDetails(
    clientReady,
    ad,
    advDetailGalleryToggle,
    showBannerBelowGallery,
    skyscraperData.sizes
  );

  useAdsenseAdDetails(ad, clientReady);

  useEffect(() => {
    markPerformance(Marks.AD_DETAILS_USE_EFFECT);
    new DataLayerBridge('adview', ad).register();
    setDataLayerReadyForAdv(true);

    // Sending the tracking event to pulse after the
    // data layer initialization.
    // This **MUST** come after DataLayerBridge.register()
    queueTask(() => {
      sendViewClassifiedAdEvent();
    }, 'background');

    if (trustPingEnabled) {
      queueTask(() => {
        initPresencePing();
      }, 'user-visible');
    }
  }, [trustPingEnabled, setDataLayerReadyForAdv]);

  useEffect(() => {
    initDidomi(getOrCreatePulse(), 'addetail');
  }, []);

  return {
    skyscraperData,
    showBannerBelowGallery,
  };
};

export { useAdDetailCommons };
