import type { AdSenseConfig } from '../types';
import { injectAdUnitConfig } from '@client/components/Adv/AdSense/adDetails/adUnits';

const adtest =
  process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT === 'prod' ? 'off' : 'on';
const pubId = 'partner-subito';
const BASE_CONFIGURATION: AdSenseConfig = {
  pageOptions: {
    pubId,
    adtest,
    styleId: '',
    adsafe: 'high',
    hl: 'it',
    channel: '',
    linkTarget: '_blank',
    adPage: 1,
  },
  generateQuery: (query: string) => query ?? '',
  adBlockOptions: {
    container: '',
    number: 3,
  },
};

const configDesktop: { [key: string]: AdSenseConfig } = {};
injectAdUnitConfig(configDesktop, BASE_CONFIGURATION, 'desktop');

const configMsite: { [key: string]: AdSenseConfig } = {};
injectAdUnitConfig(configMsite, BASE_CONFIGURATION, 'msite');

let queryString: string | null = null;
const getDomQueryForAdDetailsBlocks = function (): string | null {
  if (!queryString) {
    queryString = [...Object.keys(configDesktop), ...Object.keys(configMsite)]
      .map((id) => `#${id}`)
      .join(',');
  }
  return queryString;
};

export {
  getDomQueryForAdDetailsBlocks,
  configDesktop as adsDetailConfig,
  configMsite as configsAdSenseMsite,
};
