import React, { useState } from 'react';
import classnames from 'classnames';
import type { AdItem, UserCategoryInfo } from '@sbt-web/networking';
import { ButtonText, Caption, ShortModal } from '@sbt-web/ui';

import { adsOnlineLinkClick, adsPublishedDialogView } from '../tracking';
import UserAdsDetail from '../UserAdsDetail';

import classes from './index.module.scss';

interface Props {
  item: AdItem;
  numberOfAdsOnline: number | null;
  numberOfAdsPublished: number | null;
  categories: Array<UserCategoryInfo> | null;
  dealsDone?: number;
}

const Socialization = ({
  numberOfAdsPublished,
  numberOfAdsOnline,
  categories,
  item,
  dealsDone,
}: Props) => {
  const { advertiser } = item;
  const [isCategoryDialogOpened, setIsCategoryDialogOpened] = useState(false);
  const [isDealsDoneDialogOpened, setIsDealsDoneDialogOpened] = useState(false);

  if (
    numberOfAdsPublished == null &&
    !categories &&
    numberOfAdsOnline == null &&
    dealsDone == null
  ) {
    return null;
  }

  return (
    <div className={classes['wrapper']}>
      {numberOfAdsPublished && categories ? (
        <>
          <ShortModal
            open={isCategoryDialogOpened}
            onClose={() => {
              setIsCategoryDialogOpened(false);
            }}
            title="Categorie di pubblicazione"
            buttonConfiguration={{
              main: {
                text: 'Chiudi',
                design: 'text',
                action: () => {
                  setIsCategoryDialogOpened(false);
                },
              },
            }}
          >
            <UserAdsDetail
              totalAds={numberOfAdsPublished}
              categories={categories}
            />
          </ShortModal>

          <button
            className={classes['socialization-button']}
            onClick={() => {
              adsPublishedDialogView(item);
              setIsCategoryDialogOpened(true);
            }}
          >
            <ButtonText classes={[classes['socialization-button-number']]}>
              {numberOfAdsPublished}
            </ButtonText>
            <Caption
              weight="book"
              classes={[classes['socialization-button-text']]}
            >
              {numberOfAdsPublished === 1
                ? 'Annuncio pubblicato'
                : 'Annunci pubblicati'}
            </Caption>
          </button>
        </>
      ) : null}

      {numberOfAdsOnline ? (
        <a
          className={classes['socialization-button']}
          href={`/utente/${advertiser.userId}`}
          onClick={() => adsOnlineLinkClick(item)}
        >
          <ButtonText classes={[classes['socialization-button-number']]}>
            {numberOfAdsOnline}
          </ButtonText>
          <Caption
            weight="book"
            classes={[classes['socialization-button-text']]}
          >
            {numberOfAdsOnline === 1 ? 'Annuncio online' : 'Annunci online'}
          </Caption>
        </a>
      ) : null}

      {dealsDone !== undefined ? (
        <>
          <ShortModal
            open={isDealsDoneDialogOpened}
            onClose={() => setIsDealsDoneDialogOpened(false)}
            title="Gli affari conclusi su Subito"
            hasCloseButton
            buttonConfiguration={{
              main: {
                text: 'Ok, ho capito',
                design: 'text',
                action: () => {
                  setIsDealsDoneDialogOpened(false);
                },
              },
            }}
          >
            Il numero di affari conclusi include tutte le transazioni completate
            su Subito, indipendentemente dalla presenza di una recensione.
            Questo assicura un conteggio più accurato delle transazioni
            concluse.
          </ShortModal>

          <button
            className={classnames(classes['socialization-button'])}
            onClick={() => {
              setIsDealsDoneDialogOpened(true);
            }}
          >
            <ButtonText classes={[classes['socialization-button-number']]}>
              {dealsDone}
            </ButtonText>
            <Caption
              weight="book"
              classes={[classes['socialization-button-text']]}
            >
              {dealsDone === 1 ? 'Affare concluso' : 'Affari conclusi'}
            </Caption>
          </button>
        </>
      ) : null}
    </div>
  );
};

export default Socialization;
