export default {
  BUTTON_LABEL: 'Segnala annuncio',
  BUTTON_LABEL_SHORT: 'Segnala',
  FORM: {
    error: {
      title: 'Segnalazione non inviata',
      details:
        "C'è stato un errore e non è stato possibile inviare la tua segnalazione.\n" +
        '        Ti invitiamo a riprovare.',
      button: 'Riprova',
    },
    success: {
      button: 'Ok',
      title: 'Segnalazione inviata',
      details:
        'Grazie per la tua segnalazione, ci occuperemo di questo annuncio nel più breve tempo possibile',
    },
    title: 'Segnala annuncio',
    button: 'Invia',
    select: {
      abuseReasons: [
        { name: 'Qualità del Servizio', type: 'group' },
        { name: 'Categoria errata', value: 'WRONG_CATEGORY' },
        { name: 'Doppione', value: 'DUPLICATE' },
        { name: 'Già venduto', value: 'SOLD' },
        { name: 'Attività illecita', type: 'group' },
        { name: 'Già venduto', value: 'SOLD' },
        { name: 'Articolo non originale', value: 'NOT_ORIGINAL' },
        { name: 'Bullismo', value: 'BULLYING' },
        { name: 'Tentativo di Truffa', value: 'FRAUD' },
        { name: 'Immagini esplicite', value: 'NSFW_IMAGE' },
        { name: 'Benessere animale', value: 'ANIMAL_CARE' },
        { name: 'Prodotto vietato', value: 'UNALLOWED' },
        { name: 'Testo offensivo', value: 'OFFENSIVE' },
        { name: 'Falsificazione dei dati', value: 'FAKE_DATA' },
        { name: 'Altro', value: 'OTHER' },
      ],
      error: 'Seleziona il motivo della segnalazione',
      label: 'Motivo della segnalazione',
      placeholder: 'Seleziona il motivo',
    },
    textArea: {
      label: 'Ulteriori dettagli',
      placeholder:
        'Descrivi perché questo annuncio è inappropriato.\nPiù informazioni riesci a fornire più sarà veloce valutare la tua segnalazione.',
      error: 'Fornire ulteriori dettagli',
    },
    clause: {
      error: 'Campo obbligatorio',
    },
    email: {
      label:
        'Inserisci il tuo indirizzo email per tenere traccia della tua segnalazione e riceverne aggiornamenti',
      error: 'Campo obbligatorio',
    },
  },
};
