import { ERRORS_CODE, ERRORS_LABEL } from './errors';

export const validateMessage = (message = ''): string => {
  const messageLength = message.length;

  if (messageLength === 0) {
    return 'Scrivi il tuo messaggio';
  }

  if (message.length < 15) {
    return ERRORS_LABEL[ERRORS_CODE[4]];
  }

  if (message.length > 2900) {
    return ERRORS_LABEL[ERRORS_CODE[5]];
  }

  return '';
};
