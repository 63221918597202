import React from 'react';
import { ItemImage } from '@sbt-web/networking';
import CarouselPicture from './CarouselPicture';
import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  fullScreen?: boolean;
  index: number;
  image: ItemImage;
  reduceDataUse?: boolean;
  shouldSkipLazyLoading: boolean;
  alt: string;
  isDesktop: boolean;
  switchFullscreen?: () => void;
};

const CarouselCell = ({
  image,
  index,
  isDesktop,
  fullScreen,
  reduceDataUse,
  alt,
  shouldSkipLazyLoading,
  switchFullscreen,
}: Props) => {
  const isFirstImage = index === 0;

  return (
    <li id={`gallery-cell-${index}`} data-index={index.toString()}>
      <figure
        className={cn(styles['carousel-cell'], {
          // This class is legacy but is used by an ADV vendor as a locator
          'flickity-viewport': isDesktop,
          [styles['full-screen-figure']]: fullScreen,
        })}
      >
        {fullScreen ? (
          <CarouselPicture
            image={image}
            fullScreen={fullScreen}
            isFirstImage={isFirstImage}
            reduceDataUse={reduceDataUse}
            shouldSkipLazyLoading={shouldSkipLazyLoading}
            alt={alt}
          />
        ) : (
          <button onClick={switchFullscreen}>
            <CarouselPicture
              image={image}
              fullScreen={fullScreen}
              isFirstImage={isFirstImage}
              reduceDataUse={reduceDataUse}
              shouldSkipLazyLoading={shouldSkipLazyLoading}
              alt={alt}
            />
          </button>
        )}
      </figure>
    </li>
  );
};

export default CarouselCell;
