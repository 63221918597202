import { useEffect } from 'react';

export function useOptionalSection(
  shouldRender: boolean,
  callback: () => void
): void {
  useEffect(() => {
    if (shouldRender) {
      callback();
    }

    // we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRender]);
}
