import type {
  AdItem,
  FavoritesCounter,
  ProfileQueryData,
  PromoInfo,
  Shop,
  TrustProfileInfo,
} from '@sbt-web/networking';
import { Divider } from '@sbt-web/ui';
import type { ShippingCosts } from 'server/detail-requests/ShippingCost';
import classnames from 'classnames';
import React from 'react';
import AdInfo from '../../AdInfo';
import {
  FavoriteCounterWrapper,
  useFavoriteCounter,
} from '../../FavoriteCounter';
import SocialBar from '../../SocialBar';
import UserDetails from '../../UserDetails';
import { SectionProps } from './section';
import style from './style/general-info.module.scss';

export interface Props extends SectionProps {
  ad: AdItem;
  shop: Shop | null;
  favoriteCounter: FavoritesCounter | null;
  advertiserProfile: ProfileQueryData | null;
  shippingCosts: ShippingCosts | null;
  trustInfo: TrustProfileInfo | null;
  promo: PromoInfo | null;
  showContactButton?: boolean;
}

const GeneralInfoSection = ({
  classes = [],
  ad,
  shop,
  favoriteCounter,
  advertiserProfile,
  shippingCosts,
  trustInfo,
  promo,
  showContactButton = false,
}: Props) => {
  const [value, onSaveFavorite, onUnsaveFavorite] = useFavoriteCounter(
    favoriteCounter?.value
  );

  return (
    <section className={classnames(style['general-info'], ...classes)}>
      <SocialBar
        ad={ad}
        onSaveFavorite={onSaveFavorite}
        onUnsaveFavorite={onUnsaveFavorite}
      />
      <Divider classes={[style['ad-info-divider']]} spacing="md" />

      <div className={classnames(style['ad-info'])}>
        <AdInfo
          item={ad}
          FavoriteCounter={<FavoriteCounterWrapper value={value} />}
          shippingCosts={shippingCosts}
          promo={promo}
        />
      </div>
      <UserDetails
        advertiser={ad.advertiser}
        shop={shop}
        advertiserProfile={advertiserProfile}
        trustInfo={trustInfo}
        categoryId={ad.category.id}
        showContactButton={showContactButton}
      />
    </section>
  );
};

export default GeneralInfoSection;
