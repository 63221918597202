import React from 'react';
import { Button } from '@sbt-web/ui';
import classnames from 'classnames';

import styles from '../index.module.scss';

export interface SendOfferButtonProps {
  onClick: () => void;
  classes?: string[];
  isSecondary?: boolean;
}

export const SendOfferButton = ({
  onClick,
  classes,
  isSecondary,
}: SendOfferButtonProps) => {
  return (
    <Button
      classes={[classnames(classes, styles['send-offer-button'])]}
      size="large"
      onClick={onClick}
      design={isSecondary ? 'outline' : 'solid'}
    >
      Fai una Proposta
    </Button>
  );
};
