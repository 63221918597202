import type { AdItem } from '@sbt-web/networking';
import { buildAdDetailTarget, getAdId } from '@sbt-web/tracking';
import { getOrCreatePulse } from '@tools/tracking/utils';

export const adAbuseIntentionClick = (item: AdItem): void => {
  const adId = getAdId(item.urn);
  const { object: ClassifiedAd } = buildAdDetailTarget(item);

  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      type: 'UIElement',
      id: 'click-on-segnala-annuncio',
      elementType: 'ad abuse',
    },
    target: {
      ...ClassifiedAd,
      id: adId,
    },
  });
};

export const adAbuseSendClick = (item: AdItem): void => {
  const adId = getAdId(item.urn);
  const { object: ClassifiedAd } = buildAdDetailTarget(item);

  getOrCreatePulse()?.queueEvent({
    type: 'Send',
    object: {
      type: 'Content',
      id: 'invio-segnalazione-annuncio',
      elementType: 'ad abuse',
    },
    target: {
      ...ClassifiedAd,
      id: adId,
    },
  });
};
