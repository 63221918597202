import { AdAbuseForm } from '@client/components/Detail/AdAbuse/components/Form';
import { FormError } from '@client/components/Detail/AdAbuse/components/Form/FormError';
import { FormSuccess } from '@client/components/Detail/AdAbuse/components/Form/FormSuccess';
import Labels from '@client/components/Detail/AdAbuse/Labels';
import {
  adAbuseIntentionClick,
  adAbuseSendClick,
} from '@client/components/Detail/AdAbuse/tracking';
import { TwoFAWidget } from '@sbt-web/2fa';
import '@sbt-web/2fa/style';
import { getPublicUser } from '@sbt-web/auth';
import {
  DysnomiaClient,
  HTTPStatusCode,
  type AdItem,
} from '@sbt-web/networking';
import { Button, ButtonText, FullModal, Icon, IconSize } from '@sbt-web/ui';
import { queueTask } from '@sbt-web/utils';
import { HADES_PATH } from '@shared/constants';
import { getOrCreatePulse } from '@tools/tracking/utils';
import React, { useCallback, useEffect, useState } from 'react';
import classes from './AdAbuse.module.scss';

const dysnomiaClient = new DysnomiaClient(HADES_PATH, 'web');

enum State {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  DONE = 'DONE',
  VERIFY = 'VERIFY',
}

export type Props = {
  item: AdItem;
};

export type FormValidation = {
  isReasonValid: boolean;
  isDescriptionValid: boolean;
  isClauseValid: boolean;
  isEmailValid?: boolean;
};

export const AdAbuse = ({ item }: Props) => {
  const [opened, setOpened] = useState(false);
  const [state, setState] = useState<State | undefined>(undefined);
  const [isAnonymous, setIsAnonymous] = useState(true);

  useEffect(() => {
    // Update the state client-side if the user is logged in
    if (getPublicUser() !== null) {
      setIsAnonymous(false);
    }
  }, []);

  useEffect(() => {
    // When opening the dialog, if the user is anonymous,
    // but now has a user ID (so they have logged in in place),
    // we should update the state to reflect that.
    if (opened && isAnonymous && getPublicUser() !== null) {
      setIsAnonymous(false);
    }
  }, [opened, isAnonymous]);

  const [selectValue, setSelectValue] = useState<string | undefined>(undefined);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [clauseValue, setClauseValue] = useState(false);
  const [emailValue, setEmailValue] = useState('');

  const [token, setToken] = useState<string | undefined>(undefined);

  const [formValidation, setFormValidation] = useState<FormValidation>({
    isReasonValid: true,
    isDescriptionValid: true,
    isClauseValid: true,
    isEmailValid: true,
  });

  const openDialog = function () {
    setOpened(true);
    queueTask(() => {
      adAbuseIntentionClick(item);
    }, 'background');
  };

  const submitReport = async () => {
    if (validateForm()) {
      setState(State.LOADING);

      const response = await dysnomiaClient.postComplaint(
        {
          clause: clauseValue,
          description: textAreaValue,
          reason: selectValue as string,
          ad_urn: item.urn,
          ...(emailValue ? { email: emailValue } : {}),
        },
        token ? { Authorization: `Bearer ${token}` } : undefined
      );

      // Dysnomia responds with 201 or 202 if the report was successfully submitted
      if (
        response.status === HTTPStatusCode.Created ||
        response.status === HTTPStatusCode.Accepted
      ) {
        setState(State.DONE);
        // Cancel the token to reset the 2FA state
        setToken(undefined);
      } else if (response.status === HTTPStatusCode.Forbidden) {
        const secondFactorError = response.payload?.errors?.find(
          (e: { error_code: string }) => e.error_code === 'AUTH:2fa-required'
        );

        const authToken = secondFactorError?.detail?.['session_token'];

        if (authToken !== undefined) {
          setToken(authToken);
          setState(State.VERIFY);
        } else {
          setState(State.ERROR);
          setToken(undefined);
        }
      } else {
        setState(State.ERROR);
      }
    }
  };

  const handleWidgetError = useCallback(() => {
    setState(State.ERROR);
    // Cancel the token to reset the 2FA state
    setToken(undefined);
  }, [setState, setToken]);

  const getActions = () => {
    return [
      state === State.DONE && (
        <Button
          aria-label={Labels.FORM.success.button}
          key="success"
          design="solid"
          onClick={() => setOpened(false)}
        >
          {Labels.FORM.success.button}
        </Button>
      ),
      state === State.ERROR && (
        <Button
          aria-label={Labels.FORM.error.button}
          key="retry"
          design="solid"
          onClick={() => setState(undefined)}
        >
          {Labels.FORM.error.button}
        </Button>
      ),
      state === undefined && (
        <Button
          aria-label={Labels.FORM.button}
          key="submit"
          design="solid"
          onClick={() => {
            submitReport();

            queueTask(() => {
              adAbuseSendClick(item);
            }, 'background');
          }}
        >
          {Labels.FORM.button}
        </Button>
      ),
    ];
  };

  const validateForm = () => {
    const validation: FormValidation = {
      isReasonValid: selectValue !== undefined,
      isDescriptionValid: textAreaValue !== '',
      isClauseValid: clauseValue,
      isEmailValid: isAnonymous ? emailValue !== '' : true,
    };
    setFormValidation(validation);
    return Object.values(validation).every((valid) => valid);
  };

  return (
    <>
      <FullModal
        title={Labels.FORM.title}
        open={opened}
        onClose={() => setOpened(false)}
        actions={getActions()}
        size="medium"
      >
        {state === undefined ? (
          <AdAbuseForm
            item={item}
            formValidation={formValidation}
            setTextAreaValue={setTextAreaValue}
            setClauseValue={setClauseValue}
            setSelectValue={setSelectValue}
            setEmailValue={setEmailValue}
            selectValue={selectValue}
            textAreaValue={textAreaValue}
            clauseValue={clauseValue}
            emailValue={emailValue}
            isAnonymous={isAnonymous}
          />
        ) : null}

        {state === State.LOADING ? (
          <div role="presentation" aria-label="Attendi" id={classes.loader} />
        ) : null}

        {state === State.VERIFY && token !== undefined ? (
          <TwoFAWidget
            hadesPublicBase={process.env.NEXT_PUBLIC_HADES_BASE_URL}
            onError={handleWidgetError}
            onSuccess={submitReport}
            pulseInstance={getOrCreatePulse()}
            token={token}
            source="ad-abuse"
            layout="MODAL"
          />
        ) : null}

        {state === State.DONE ? <FormSuccess /> : null}
        {state === State.ERROR ? <FormError /> : null}
      </FullModal>
      <Button
        design="text"
        onClick={openDialog}
        classes={[classes['ad-abuse']]}
        aria-label={Labels.BUTTON_LABEL}
        icon={<Icon name="TriangleExclamation" iconSize={IconSize.SM} />}
      >
        <ButtonText classes={[classes['ad-button-label'], classes['desktop']]}>
          {Labels.BUTTON_LABEL}
        </ButtonText>
        <ButtonText classes={[classes['ad-button-label'], classes['mobile']]}>
          {Labels.BUTTON_LABEL_SHORT}
        </ButtonText>
      </Button>
    </>
  );
};
