import { urnTransformer } from '@sbt-web/utils';
import { transformCategory } from '@tools/tracking/transformer';
import { getDataLayer } from '@tools/tracking/utils';

interface Target {
  id?: string;
  type: string;
  category: string;
  name: string;
  adId?: number;
  contentId?: string;
}

interface Page {
  pageType: string;
  '@id': string;
  '@type': string;
}

interface TrackingData {
  type: string;
  intent: string;
  object: {
    id: string;
    type: string;
    elementType: string;
    elementSubtype: string;
    position: string;
    label?: string;
  };
  target: Target;
  page: Page;
}

const page = {
  pageType: 'ad_detail',
  '@id': 'sdrn:subito:page:ad_detail',
  '@type': 'Page',
};

export const buildTrackingViewData = (position: string): TrackingData => {
  const rawAd = getDataLayer().ads[0];
  const { adId, contentId } = urnTransformer(rawAd.urn) ?? {};
  const target = {
    id: adId,
    type: 'ClassifiedAd',
    category: transformCategory(rawAd.category),
    name: document.title,
    adId: adId ? Number.parseInt(adId) : undefined,
    contentId,
  };
  return {
    type: 'View',
    intent: 'View',
    object: {
      id: 'yatmo_map',
      type: 'UIElement',
      elementType: 'Map',
      elementSubtype: 'yatmo',
      position,
    },
    target,
    page,
  };
};

export const buildTrackingFilterData = (label: string): TrackingData => {
  const rawAd = getDataLayer().ads[0];
  const { adId, contentId } = urnTransformer(rawAd.urn) ?? {};
  const target = {
    id: adId,
    type: 'ClassifiedAd',
    category: transformCategory(rawAd.category),
    name: document.title,
    adId: adId ? Number.parseInt(adId) : undefined,
    contentId,
  };
  return {
    type: 'Click',
    intent: 'Filter',
    object: {
      id: 'yatmo_map',
      type: 'UIElement',
      elementType: 'Button',
      elementSubtype: 'yatmo',
      position: 'LowerLeft',
      label,
    },
    page,
    target,
  };
};

export const buildTrackingPOIData = (
  macroPOICategory: string,
  poiID: string
): TrackingData => {
  const rawAd = getDataLayer().ads[0];
  const { adId, contentId } = urnTransformer(rawAd.urn) ?? {};
  const target = {
    id: adId,
    type: 'ClassifiedAd',
    category: transformCategory(rawAd.category),
    name: document.title,
    adId: adId ? Number.parseInt(adId) : undefined,
    contentId,
  };
  return {
    type: 'View',
    intent: 'View',
    object: {
      id: `${poiID}:yatmo_map`,
      type: 'UIElement',
      elementType: 'Map_pin',
      elementSubtype: 'POI',
      label: macroPOICategory,
      position: 'in-map',
    },
    target,
    page,
  };
};

export const buildTrackingFullScreenData = (
  fullScreenStatus: string
): TrackingData => {
  const rawAd = getDataLayer().ads[0];
  const { adId, contentId } = urnTransformer(rawAd.urn) ?? {};
  const target = {
    id: adId,
    type: 'ClassifiedAd',
    category: transformCategory(rawAd.category),
    name: document.title,
    adId: adId ? Number.parseInt(adId) : undefined,
    contentId,
  };
  return {
    type: 'View',
    intent: 'View',
    object: {
      id: 'yatmo_map',
      type: 'UIElement',
      elementType: 'Button',
      elementSubtype: 'Full Screen',
      label: fullScreenStatus,
      position: 'in-map',
    },
    target,
    page,
  };
};

export const buildTrackingZoomData = (zoomStatus: string): TrackingData => {
  const rawAd = getDataLayer().ads[0];
  const { adId, contentId } = urnTransformer(rawAd.urn) ?? {};
  const target = {
    id: adId,
    type: 'ClassifiedAd',
    category: transformCategory(rawAd.category),
    name: document.title,
    adId: adId ? Number.parseInt(adId) : undefined,
    contentId,
  };
  return {
    type: 'View',
    intent: 'View',
    object: {
      id: 'yatmo_map',
      type: 'UIElement',
      elementType: 'Button',
      elementSubtype: 'Zoom',
      label: zoomStatus,
      position: 'in-map',
    },
    target,
    page,
  };
};
