import {
  CategoryId,
  CategoryStore,
  FeatureURI,
  type AdItem,
  type ItemCategory,
  type ItemFeature,
  type ItemImage,
  type KeyValuePair,
} from '@sbt-web/networking';
import { jsonLdScriptProps } from 'react-schemaorg';
import type {
  Brand,
  OfferItemCondition,
  Product,
  WithContext,
} from 'schema-dts';

const notAllowedCategoryIds = [
  CategoryId.OfferteLavoro,
  CategoryId.Servizi,
  CategoryId.Candidati,
];

const notAllowedMacroCategoryIds = [CategoryId.Immobili];

function getImages(images: Array<ItemImage>): string[] | undefined {
  const imageUrls = images?.map(
    (img) => `${img.cdnBaseUrl}?rule=fullscreen-1x-auto`
  );

  if (imageUrls == null || imageUrls.length === 0) {
    return undefined;
  } else {
    return imageUrls;
  }
}

function getPrice(price: ItemFeature): number {
  return price?.values[0]?.key ? Number(price.values[0].key) : 0;
}

const getItemCondition = (
  conditionFeature: ItemFeature
): OfferItemCondition | undefined => {
  const condition = conditionFeature?.values[0]?.key;

  if (condition === '10') {
    return 'https://schema.org/NewCondition';
  } else if (['20', '30', '40', '50'].includes(condition)) {
    return 'https://schema.org/UsedCondition';
  } else {
    return undefined;
  }
};

function shouldShowSchema(
  category: ItemCategory,
  adType: KeyValuePair
): boolean {
  if (adType.value !== 'In vendita') return false;
  // In an ad detail we can't have a macro as the ad's category, so we would
  // have an undefined result here
  const macroCategory = CategoryStore.getMacroByCategoryId(category.id);
  if (
    notAllowedCategoryIds.includes(category.id) ||
    (macroCategory != null &&
      notAllowedMacroCategoryIds.includes(macroCategory.id))
  ) {
    return false;
  }
  return true;
}

function isMotorCategory(category: ItemCategory): boolean {
  return category.id === CategoryId.Auto || category.id === CategoryId.Moto;
}

function getBrand(adItem: AdItem): Brand | null {
  if (isMotorCategory(adItem.category)) {
    const key: string =
      adItem.category.id === CategoryId.Auto ? '/car' : '/bike';

    if (adItem.features[key]) {
      const [brand] = adItem.features[key].values;
      return {
        '@type': 'Brand',
        name: brand.value,
      };
    } else return null;
  } else return null;
}

const detailSchema = (
  adItem: AdItem
): ReturnType<typeof jsonLdScriptProps> | null => {
  if (shouldShowSchema(adItem.category, adItem.type)) {
    const product: WithContext<Product> = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: adItem.subject,
      image: getImages(adItem.images),
      description: adItem.body,
      offers: {
        '@type': 'Offer',
        url: adItem.urls.default,
        priceCurrency: 'EUR',
        price: getPrice(adItem.features[FeatureURI.Price]),
        availability: 'https://schema.org/InStock',
        itemCondition: getItemCondition(
          adItem.features[FeatureURI.ItemCondition]
        ),
      },
    };

    const brand = getBrand(adItem);
    if (brand !== null) {
      product.brand = brand;
    }

    return jsonLdScriptProps<Product>(product);
  }
  return null;
};

export default detailSchema;
export { getImages, getBrand, shouldShowSchema, getPrice };
