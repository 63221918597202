import type { AdItem } from '@sbt-web/networking';
import { queueTask } from '@sbt-web/utils';
import { useEffect } from 'react';
import { runAdsense } from '../integration/runAdsense';
import { adsDetailConfig, getDomQueryForAdDetailsBlocks } from './config';

const run = function (ad: AdItem): void {
  const DOMQueryString = getDomQueryForAdDetailsBlocks();

  const categoryLabel = ad.category.label;
  const query = ad.subject;
  if (DOMQueryString) {
    runAdsense(query, categoryLabel, DOMQueryString, adsDetailConfig);
  }
};

export const useAdsenseAdDetails = function (
  ad: AdItem,
  clientReady?: boolean
): void {
  useEffect(() => {
    // Load only when the user scrolls,
    // because all adsense blocks are below the fold
    if (clientReady) {
      document.addEventListener(
        'scroll',
        () => {
          queueTask(() => run(ad), 'user-visible');
        },
        {
          once: true,
          passive: true,
        }
      );
    }
  }, [ad, clientReady]);
};
