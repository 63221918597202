import classnames from 'classnames';
import React from 'react';
import Infocar, { Props as InfocarProps } from '../../Infocar';
import type { SectionProps } from './section';

const InfocarSection: React.FunctionComponent<SectionProps & InfocarProps> = ({
  infocarData,
  classes = [],
}) => (
  <section className={classnames(...classes)}>
    <Infocar infocarData={infocarData} />
  </section>
);

export default InfocarSection;

export type { InfocarProps };
