import type { AdItem, PresenceStatus } from '@sbt-web/networking';
import { Button } from '@sbt-web/ui';
import React from 'react';
import { useInfo } from './useInfo';
import Socialization from './components/Socialization';
import classes from './AdPrivateBox.module.scss';
import { PrivateUserProfileBadge } from '../PrivateUserProfileBadge';

interface Props {
  name: string;
  item: AdItem;
  picture?: string;
  score?: number;
  presenceText?: string;
  presenceStatus?: PresenceStatus;
  reviewCount?: number;
  showContactButton?: boolean;
  dealsDone?: number;
}

export const AdPrivateBox = (props: Props) => {
  const { message, numberOfAdsOnline, numberOfAdsPublished, categories } =
    useInfo(props.item.advertiser.userId);

  return (
    <aside className={classes['private-bottom-box']}>
      <PrivateUserProfileBadge
        name={props.name}
        publishFromText={message ?? ''}
        userId={props.item.advertiser.userId}
        pulseEventName="click-on-name-advertiser-AD-Detail"
        picture={props.picture}
        score={props.score}
        presenceText={props.presenceText}
        presenceStatus={props.presenceStatus}
        reviewCount={props.reviewCount}
      />
      {props.showContactButton ? (
        <div className={classes.contactButtonAndSocialization}>
          <Socialization
            numberOfAdsOnline={numberOfAdsOnline}
            numberOfAdsPublished={numberOfAdsPublished}
            categories={categories}
            item={props.item}
            dealsDone={props.dealsDone}
          />
          <Button
            design="outline"
            onClick={() => {
              window.dispatchEvent(
                new CustomEvent('subito:AdView:BtnAuthorReply')
              );
            }}
          >
            Contatta
          </Button>
        </div>
      ) : (
        <Socialization
          numberOfAdsOnline={numberOfAdsOnline}
          numberOfAdsPublished={numberOfAdsPublished}
          categories={categories}
          item={props.item}
          dealsDone={props.dealsDone}
        />
      )}
    </aside>
  );
};
