import {
  HTTPStatusCode,
  PolymatheiaClient,
  ProfileQueryData,
} from '@sbt-web/networking';
import { WEB_API_CHANNEL } from '@shared/constants';

export const polymatheiaClient = new PolymatheiaClient(
  process.env.NEXT_PUBLIC_HADES_BASE_URL,
  WEB_API_CHANNEL
);

function validateNameClient(name = ''): string | undefined {
  if (name.length === 0) {
    return ERRORS_HANDLED['POLYMATHEIA:empty-user-name'];
  }
  if (name.length < 2) {
    return ERRORS_HANDLED['POLYMATHEIA:name-too-short'];
  }
}

export const ERRORS_HANDLED = {
  'POLYMATHEIA:bad-user-name': 'Parole non consentite',
  'POLYMATHEIA:empty-user-name': 'Inserisci il tuo nome',
  'POLYMATHEIA:name-too-short':
    'Il tuo nome deve avere tra i 2 e i 50 caratteri',
  'POLYMATHEIA:name-too-long':
    'Il tuo nome deve avere tra i 2 e i 50 caratteri',
};

export const updateUsername = async (
  userId: string,
  username: string
): Promise<string | undefined> => {
  const clientMsg = validateNameClient(username);
  if (clientMsg) {
    return clientMsg;
  }
  const user = await polymatheiaClient.update(userId, { username }, 'patch');
  if (user.status !== HTTPStatusCode.OK) {
    let msg = 'Si è verificato un errore imprevisto, si prega di riprovare.';
    if (user.payload?.errors) {
      const errors = user.payload?.errors as Array<{
        error_code: string;
      }>;
      errors.forEach((e) => {
        const key = e.error_code as keyof typeof ERRORS_HANDLED;
        if (ERRORS_HANDLED[key]) {
          msg = ERRORS_HANDLED[key];
        }
      });
    }
    return msg;
  }
};

export const getUsername = async (
  userId: string
): Promise<ProfileQueryData | undefined> => {
  const user = await polymatheiaClient.getQuery(userId);
  return user?.userProfile;
};
