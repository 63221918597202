import {
  AdSenseConfig,
  ShortConfiguration,
} from '@client/components/Adv/AdSense/types';
import { msiteBottom } from './msite-bottom';
import { desktopBottom } from './desktop-bottom';

const msiteAdUnits: ShortConfiguration[] = [...msiteBottom];
const desktopAdUnits: ShortConfiguration[] = [...desktopBottom];

export const injectAdUnitConfig = (
  globalConfig: { [key: string]: AdSenseConfig },
  baseAdUnitConfig: AdSenseConfig,
  target: 'desktop' | 'msite'
): void => {
  (target === 'desktop' ? desktopAdUnits : msiteAdUnits).forEach(
    ({ id, pageOptions, adBlockOptions }: ShortConfiguration) => {
      globalConfig[id] = {
        ...baseAdUnitConfig,
        pageOptions: {
          ...baseAdUnitConfig.pageOptions,
          ...pageOptions,
        },
        adBlockOptions: {
          ...baseAdUnitConfig.adBlockOptions,
          ...adBlockOptions,
          container: id,
        },
      };
    }
  );
};
