import { CriteoContainer } from '@sbt-web/adv';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import {
  AdItem,
  CategoryId,
  ItemCategory,
  ItemFeature,
  ItemGeo,
  KeyValuePair,
} from '@sbt-web/networking';
import type { Pulse } from '@sbt-web/tracking';
import { getOrCreatePulse } from '@tools/tracking/utils';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import css from './style/skyscraper.module.scss';
import { LazyPrestiPayBox } from '@client/components/Detail/PrestiPayBoxAncillaryService';

export interface LoanProps {
  price: ItemFeature;
  title?: string;
  item: AdItem;
  landingUrl?: string;
  assetsBase: string;
  pulseInstance?: Pulse;
  houstonTrackAncillary?: (e: string) => void;
}

const AgosBox = dynamic<LoanProps>(() =>
  import('@sbt-web/ancillary-services').then((module) => module.AgosBox)
);
AgosBox.displayName = 'DynamicAgosBox';

const CreditAgricoleBox = dynamic<LoanProps>(() =>
  import('@sbt-web/ancillary-services').then(
    (module) => module.CreditAgricoleBox
  )
);
CreditAgricoleBox.displayName = 'DynamicCreditAgricoleBox';

const TextLinkBox = dynamic(() => import('./TextLinkBox'), {
  ssr: false,
});
TextLinkBox.displayName = 'DynamicTextLinkBox';

interface Props {
  item: AdItem;
  newCreditAgricoleToggle?: boolean;
}

const asideAdv = <div id="apn_aside" className={css['padding']} />;

const motoriCategories = [
  CategoryId.Motori,
  CategoryId.Auto,
  CategoryId.Moto,
  CategoryId.Nautica,
  CategoryId.CaravanECamper,
  CategoryId.VeicoliCommerciali,
];

const crediteAgricoleRealestateCategories = [
  CategoryId.TerreniRustici,
  CategoryId.UfficiLocali,
];

export const provinceCrediteAgricoleList = [
  'CE',
  'NA',
  'SA',
  'PC',
  'BO',
  'MO',
  'PR',
  'RE',
  'FE',
  'FC',
  'RA',
  'RN',
  'GO',
  'PN',
  'TS',
  'UD',
  'FR',
  'LT',
  'RI',
  'RM',
  'VT',
  'GE',
  'IM',
  'SP',
  'SV',
  'BG',
  'CO',
  'LC',
  'MI',
  'MB',
  'VA',
  'BS',
  'CR',
  'LO',
  'MN',
  'PV',
  'SO',
  'AN',
  'FM',
  'MC',
  'PU',
  'AL',
  'AT',
  'BI',
  'CN',
  'NO',
  'TO',
  'VB',
  'VC',
  'AG',
  'CL',
  'CT',
  'EN',
  'ME',
  'PA',
  'RG',
  'SR',
  'TP',
  'AR',
  'FI',
  'GR',
  'LI',
  'LU',
  'MS',
  'PI',
  'PT',
  'PO',
  'SI',
  'BZ',
  'TN',
  'PG',
  'TR',
  'AO',
  'BL',
  'PD',
  'RO',
  'TV',
  'VE',
  'VR',
  'VI',
];

export const shouldRenderAgosBox = ({
  adType,
  category,
}: {
  adType: KeyValuePair;
  category: ItemCategory;
}): boolean =>
  motoriCategories.includes(category.id) &&
  adType.value.toLocaleLowerCase() === 'in vendita';

const shouldRenderCreditAgricoleBox = ({
  adType,
  category,
  geo,
  price,
}: {
  adType: KeyValuePair;
  category: ItemCategory;
  geo: ItemGeo;
  price: ItemFeature;
}): boolean =>
  price?.values?.[0] &&
  Number.parseInt(price?.values?.[0].key, 10) >= 40000 &&
  Number.parseInt(price?.values?.[0].key, 10) <= 2000000 &&
  geo.city?.shortName !== undefined &&
  provinceCrediteAgricoleList.includes(geo.city.shortName) &&
  crediteAgricoleRealestateCategories.includes(category.id) &&
  adType.value.toLocaleLowerCase() === 'in vendita';

export function getLoanBoxTitle(category: ItemCategory) {
  if (category.id === CategoryId.Auto) {
    return 'la tua auto';
  } else if (category.id === CategoryId.Moto) {
    return 'la tua moto';
  } else if (category.id === CategoryId.Nautica) {
    return 'la tua imbarcazione';
  } else if (category.id === CategoryId.CaravanECamper) {
    return 'il tuo camper';
  } else if (category.id === CategoryId.VeicoliCommerciali) {
    return 'il tuo veicolo';
  } else {
    return '';
  }
}

export const Skyscraper: React.FunctionComponent<Props> = ({
  item,
  newCreditAgricoleToggle,
}) => {
  const category = item.category;
  const adType = item.type;
  const advertiserType = item.advertiser.type;
  const price = item.features['/price'];

  const { optimizely } = useContext(OptimizelySubitoContext);

  return (
    <>
      {advertiserType === 1 ? <div id="pro_profile">{asideAdv}</div> : asideAdv}
      {shouldRenderAgosBox({ category, adType }) ? (
        <AgosBox
          assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
          item={item}
          price={price}
          pulseInstance={getOrCreatePulse()}
          title={getLoanBoxTitle(category)}
          houstonTrackAncillary={(t) => {
            optimizely?.track(t);
          }}
        />
      ) : null}
      {shouldRenderCreditAgricoleBox({
        category,
        adType,
        geo: item.geo,
        price,
      }) && !newCreditAgricoleToggle ? (
        <CreditAgricoleBox
          item={item}
          price={price}
          assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
          pulseInstance={getOrCreatePulse()}
        />
      ) : null}
      {
        <LazyPrestiPayBox
          price={price}
          assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
          item={item}
          pulseInstance={getOrCreatePulse()}
          isDesktop={true}
        />
      }

      <TextLinkBox item={item} />

      <CriteoContainer slotid="apn_box_aside" zoneid={1750328} />
    </>
  );
};

Skyscraper.displayName = 'Skyscraper';

export default Skyscraper;
