import {
  AnubisClient,
  BaseCategory,
  CategoryStore,
  HTTPStatusCode,
  SearchParams,
  type AdItem,
} from '@sbt-web/networking';
import { WEB_API_CHANNEL } from '@shared/constants';
import { registerError } from '@tools/errorHelpers';
import { adTypesToSlugMap } from '@tools/search/values';
import type { Response } from 'express';

const anubiClient = new AnubisClient(
  process.env.ANUBI_BASE_URL as string,
  WEB_API_CHANNEL,
  Number.parseInt(process.env.NEXT_PUBLIC_ANUBI_TIMEOUT_MS, 10)
);

const fetchItem = async (
  id: string,
  environmentId: string | undefined,
  res: Response
): Promise<AdItem | null> => {
  let item: AdItem | null = null;

  try {
    const itemResponse = await anubiClient.getSingleAd(id, environmentId);

    if (
      itemResponse.status !== HTTPStatusCode.OK ||
      itemResponse.ad == undefined
    ) {
      registerError(res, itemResponse.status, 'WARN', {
        service: 'ANUBI',
        message: new Error(
          `Couldn't fetch item from Anubi: ${itemResponse.info}`
        ),
      });
    }

    item = itemResponse.ad ?? null;
  } catch (e) {
    registerError(res, HTTPStatusCode.BadGateway, 'FATAL', {
      service: 'ANUBI',
      message:
        e instanceof Error || typeof e === 'string'
          ? e
          : `Error on Anubi call: ${e}`,
    });
  }

  return item;
};

interface BackupSearch {
  backupItems: AdItem[];
  category: BaseCategory;
  categoryDefaultUrl: string;
}

const fetchBackupItems = async (
  categoryName: string
): Promise<BackupSearch> => {
  const category = CategoryStore.getCategoryByFriendly(categoryName);

  const categoryDefaultUrl = `/annunci-italia/${
    adTypesToSlugMap[category.defaultAdType]
  }/${category.friendly}/`;

  try {
    const backupItemsSearch = await anubiClient.search({
      c: category.id.toString(),
      lim: 6,
      t: category.defaultAdType,
    });

    return {
      category,
      categoryDefaultUrl,
      backupItems: backupItemsSearch.search?.ads ?? [],
    };
  } catch {
    return {
      category,
      categoryDefaultUrl,
      backupItems: [],
    };
  }
};

const searchForRecommendation = async (
  searchParams: SearchParams
): Promise<AdItem[]> => {
  const client = new AnubisClient(
    process.env.NEXT_PUBLIC_HADES_BASE_URL,
    WEB_API_CHANNEL
  );
  try {
    const backupItemsSearch = await client.search({
      ...searchParams,
      c: '2',
      lim: 10,
      advt: '1',
    });

    return backupItemsSearch.search?.ads ?? [];
  } catch {
    return [];
  }
};

export { fetchItem, fetchBackupItems, searchForRecommendation };
