import { Button, Icon, IconSize, InputFooter, InputHeader } from '@sbt-web/ui';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Attach } from './Icons';
import { ERRORS_LABEL, getError } from '../../utils/errors';
import styles from './index.module.scss';

interface AttachmentFieldProps {
  placeholder: string;
  name: string;
  onChange: (files: File | undefined) => void;
  errors: string[];
}

export const ERRORS_CODE = [
  'REPLY:attachment-size-invalid',
  'REPLY:attachment-name-long',
  'REPLY:attachment-name-short',
  'REPLY:unsupported-attachment',
  'REPLY:too-large-request',
];

export const AttachmentField = ({
  placeholder,
  name,
  onChange,
  errors,
}: AttachmentFieldProps) => {
  const [labelText, setLabelText] = useState<string>(placeholder);
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const code: string | undefined = getError(errors, ERRORS_CODE);
    if (code) {
      setError(ERRORS_LABEL[code]);
    }
  }, [errors]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files?.length > 0) {
      setLabelText(files[0].name);
      setHasFile(true);
      setError(undefined);
      onChange(files[0]);
    }
  };

  const handleRemoveFile = () => {
    setLabelText(placeholder);
    setHasFile(false);
    setError(undefined);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onChange(undefined);
  };

  return (
    <div id="attachment_component">
      <InputHeader name={name} label="Curriculum" />
      <div className={styles.container}>
        <input
          ref={inputRef}
          className={styles.fileInput}
          id={name}
          type="file"
          name={name}
          accept={'.pdf,.rtf'}
          onChange={handleChange}
        />
        <label
          className={classnames(styles.fakeFileInput, {
            [styles.withFile]: hasFile,
          })}
          htmlFor={name}
        >
          {!hasFile && <Attach />}
          {labelText}
        </label>
        {hasFile && (
          <Button
            design="text"
            onClick={handleRemoveFile}
            size="medium"
            icon={<Icon name="Bin" iconSize={IconSize.MD} />}
            aria-label="elimina curriculum"
          />
        )}
      </div>
      {error && <InputFooter message={error} isError={true} />}
    </div>
  );
};
