import React from 'react';
import { ItemImage } from '@sbt-web/networking';
import { DeviceWidth } from '@sbt-web/ui';
import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  fullScreen?: boolean;
  image: ItemImage;
  isFirstImage: boolean;
  reduceDataUse?: boolean;
  shouldSkipLazyLoading: boolean;
  alt: string;
};

const mobileRule = 'gallery-mobile';
const desktopRule = 'gallery-desktop';
const galleryRule = 'fullscreen';

const buildSrcSet = (cdnBaseUrl: string, ruleQuery: string) =>
  `${cdnBaseUrl}?rule=${ruleQuery}-1x-auto 1x, ${cdnBaseUrl}?rule=${ruleQuery}-2x-auto 2x, ${cdnBaseUrl}?rule=${ruleQuery}-3x-auto 3x`;

const CarouselPicture = ({
  fullScreen,
  image,
  isFirstImage,
  reduceDataUse,
  shouldSkipLazyLoading,
  alt,
}: Props) => {
  const fullScreenSrcSet = buildSrcSet(image.cdnBaseUrl, galleryRule);
  const desktopSrcSet = fullScreen
    ? fullScreenSrcSet
    : buildSrcSet(image.cdnBaseUrl, desktopRule);
  const mobileSrcSet = fullScreen
    ? fullScreenSrcSet
    : buildSrcSet(image.cdnBaseUrl, mobileRule);

  return (
    <picture
      className={cn({
        [styles['full-screen-picture']]: fullScreen,
      })}
    >
      <source
        srcSet={desktopSrcSet}
        media={`(min-width: ${DeviceWidth.tablet.min})`}
      />
      <img
        src={`${image.cdnBaseUrl}?rule=${mobileRule}-1x-auto`}
        srcSet={reduceDataUse ? undefined : mobileSrcSet}
        alt={isFirstImage ? alt : ''}
        className={cn(styles.image, {
          [styles['full-screen-img']]: fullScreen,
        })}
        decoding="async"
        loading={
          // We want to load the first image eagerly to make the gallery feel faster.
          // If lazy loading is disabled, we re-render and eagerly load all images.
          isFirstImage || shouldSkipLazyLoading ? 'eager' : 'lazy'
        }
        fetchPriority={isFirstImage ? 'high' : 'low'} // NOSONAR
      />
    </picture>
  );
};

export default CarouselPicture;
