import React from 'react';
import { Button, Icon, IconSize } from '@sbt-web/ui';

import classes from '../index.module.scss';

export type ContactButtonProps = {
  onClick: () => void;
  isSecondary?: boolean;
  showLabel?: boolean;
  showIcon?: boolean;
};

export const ContactButton = ({
  onClick,
  isSecondary = false,
  showLabel = true,
  showIcon,
}: ContactButtonProps) => (
  <Button
    design={isSecondary ? 'outline' : undefined}
    onClick={onClick}
    classes={[classes.contactButton]}
    icon={showIcon ? <Icon name="Mail" iconSize={IconSize.MD} /> : undefined}
    size="large"
  >
    {showLabel && 'Contatta'}
  </Button>
);
