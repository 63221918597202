import type { ItemMap } from '@sbt-web/networking';
import classnames from 'classnames';
import React from 'react';
import Map from '../../Map';
import type { SectionProps } from './section';

interface OwnProps {
  map?: ItemMap;
}

type Props = OwnProps & SectionProps;

function MapSection({ classes = [], map }: Props) {
  return (
    <section className={classnames(...classes)}>
      <Map map={map} />
    </section>
  );
}

export default MapSection;
