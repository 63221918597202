import { OpeningTime as OpeningTimeType } from '@sbt-web/networking';
import classes from './index.module.scss';
import { BodyText, Subhead } from '@sbt-web/ui';
import React from 'react';
import { getOpeningTime } from '@client/components/Detail/AdvertiserInfo/AdDetailCompanyPro/mapper';

interface OpeningTimeProps {
  openingTime: OpeningTimeType;
}

export interface IOpeningTimeRow {
  type: string;
  name: string;
  firstColumn: string;
  secondColumn: string;
}

const OpeningTimeRow = ({
  name,
  firstColumn,
  secondColumn,
}: IOpeningTimeRow) => (
  <tr>
    <th className={classes.day}>
      <BodyText>{name}</BodyText>
    </th>
    <td>
      <BodyText classes={[classes.hours_text]}>{firstColumn}</BodyText>
    </td>
    <td>
      <BodyText classes={[classes.hours_text]}>{secondColumn}</BodyText>
    </td>
  </tr>
);

export const OpeningTimeTable = ({ openingTime }: OpeningTimeProps) => {
  const tableRows = getOpeningTime(openingTime).map((row, index) => (
    <OpeningTimeRow key={index} {...row} />
  ));

  return (
    <table className={classes.opening_time}>
      <caption>
        <Subhead>Orari</Subhead>
      </caption>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
