export const ERRORS_CODE = [
  'REPLY:attachment-size-invalid',
  'REPLY:attachment-name-long',
  'REPLY:attachment-name-short',
  'REPLY:unsupported-attachment',
  'REPLY:request-body-short',
  'REPLY:request-body-long',
  'REPLY:too-large-request',
];

export const ERRORS_LABEL: Record<string, string> = {
  [ERRORS_CODE[0]]:
    "L'allegato ha un peso superiore a quello consentito (2.5 MB)",
  [ERRORS_CODE[1]]: 'Seleziona un file con un nome più corto',
  [ERRORS_CODE[2]]: 'Carica un allegato con un nome lungo almeno 4 caratteri',
  [ERRORS_CODE[3]]:
    "L'allegato non è un tipo di file consentito. Sono accettati allegati in formato PDF o RTF.",
  [ERRORS_CODE[4]]: 'Scrivi un messaggio più lungo',
  [ERRORS_CODE[5]]: 'Scrivi un messaggio più corto',
  [ERRORS_CODE[6]]:
    "L'allegato ha un peso superiore a quello consentito (2.5 MB)",
};

export const getError = (
  errorList: string[],
  errors: string[]
): string | undefined =>
  errorList.find((errorCode) => errors.find((error) => errorCode === error));
