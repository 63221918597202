import React from 'react';
import { BodyText, ButtonText } from '@sbt-web/ui';
import classes from './index.module.scss';

interface SocializationProps {
  adsCount?: number;
  url?: string;
}

type OnlineAdsProps = Pick<SocializationProps, 'adsCount'>;

type ShopLinkProps = Pick<SocializationProps, 'url'>;

const ShopLink = ({ url }: ShopLinkProps) => (
  <a href={url} className={classes.anchor_button} rel="noopener">
    <ButtonText>Vai allo Shop</ButtonText>
  </a>
);

const OnlineAds = ({ adsCount }: OnlineAdsProps) => (
  <div className={classes['online_ads']}>
    <BodyText size="small" classes={[classes.body_text]}>
      {`${adsCount || 0} annunc${adsCount === 1 ? 'io' : 'i'} online`}
    </BodyText>
  </div>
);

const Socialization = ({ adsCount, url }: SocializationProps) =>
  adsCount != undefined && url != undefined ? (
    <aside className={classes.socialization}>
      <OnlineAds adsCount={adsCount} />
      <ShopLink url={url} />
    </aside>
  ) : null;

Socialization.displayName = 'BottomShopBox_Socialization';

export default Socialization;
