import React from 'react';
import cn from 'classnames';
import Labels from '../../Labels';
import { Caption, Headline5 } from '@sbt-web/ui';
import classes from './index.module.scss';

export const FormError = function () {
  return (
    <div className={cn(classes['abuse-error-wrapper'])}>
      <Headline5>{Labels.FORM.error.title}</Headline5>
      <Caption weight="book">{Labels.FORM.error.details}</Caption>
    </div>
  );
};
