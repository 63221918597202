import React from 'react';

import classes from './index.module.scss';

export const ShieldIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="6 6 20 20"
    height={24}
    width={24}
    className={classes.icon}
  >
    <path
      d="M15.601 9.784a6.12 6.12 0 0 1-.685.516l-4.205 1.266a.6.6 0 0 0-.595.532l.596.068a57.972 57.972 0 0 0-.596-.068v.003l-.001.007-.003.021a10.232 10.232 0 0 0-.028.368 15.65 15.65 0 0 0 .343 4.3c.563 2.485 1.972 5.382 5.376 6.566l.003.001c.118.04.248.045.375.004l.016-.005c3.403-1.184 4.813-4.081 5.375-6.565.283-1.246.36-2.416.368-3.272a15.077 15.077 0 0 0-.045-1.318 6.144 6.144 0 0 0-.008-.079l-.002-.021V12.099h-.001l-.596.067.596-.068a.6.6 0 0 0-.595-.532H21.268l-.082-.004a6.252 6.252 0 0 1-.328-.024 8.973 8.973 0 0 1-1.175-.198 8.704 8.704 0 0 1-2.447-.978 7.505 7.505 0 0 1-.856-.593.598.598 0 0 0-.779.015Zm-.196.37-.49.146-4.204 1.266m0 0h.024l.088-.004c.079-.003.197-.01.348-.024a9.33 9.33 0 0 0 1.22-.199c.772-.178 1.684-.492 2.525-1.039l.49-.147m.607.842c1.18.844 2.422 1.283 3.394 1.513.533.126.99.19 1.32.223.01.208.016.474.013.784a14.46 14.46 0 0 1-.338 3.018c-.506 2.235-1.696 4.593-4.402 5.625-2.706-1.032-3.896-3.39-4.403-5.625a14.46 14.46 0 0 1-.324-3.8h.01a10.54 10.54 0 0 0 1.378-.224c.986-.228 2.234-.666 3.352-1.514Zm2.26 4.2a.5.5 0 0 1 .001.707l-2.403 2.416a.5.5 0 0 1-.71 0l-1.441-1.45a.5.5 0 0 1 .709-.705l1.087 1.093 2.05-2.06a.5.5 0 0 1 .707-.001Z"
      clipRule="evenodd"
    />
  </svg>
);

export const ShopIcon = () => (
  <svg
    viewBox="0 0 48 48"
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    className={classes.icon}
  >
    <path
      d="M34.77 15.5c0 1.657 1.377 3 3.076 3 1.654 0 3.004-1.273 3.074-2.869L38.02 9H9.981L7.08 15.631c.07 1.596 1.42 2.869 3.074 2.869 1.7 0 3.077-1.343 3.077-3 0-.828.689-1.5 1.538-1.5.85 0 1.539.672 1.539 1.5 0 1.657 1.377 3 3.077 3 1.699 0 3.077-1.343 3.077-3 0-.828.688-1.5 1.538-1.5.85 0 1.538.672 1.538 1.5 0 1.657 1.378 3 3.077 3 1.7 0 3.077-1.343 3.077-3 0-.828.69-1.5 1.539-1.5.85 0 1.538.672 1.538 1.5zM12 21.225V39h4.465c-.007-7.412 2.452-11.5 7.535-11.5s7.542 4.088 7.535 11.5H36V21.225a6.175 6.175 0 0 1-2.77-1.756 6.208 6.208 0 0 1-4.615 2.031A6.208 6.208 0 0 1 24 19.469a6.208 6.208 0 0 1-4.615 2.031 6.208 6.208 0 0 1-4.616-2.031A6.175 6.175 0 0 1 12 21.225zm-3 .17c-2.835-.525-4.981-2.945-5-5.857-.005-.213.035-.431.126-.64l3.238-7.4A2.5 2.5 0 0 1 9.654 6h28.692a2.5 2.5 0 0 1 2.29 1.498l3.238 7.4c.091.209.131.427.126.64-.019 2.912-2.165 5.332-5 5.857V39.5a2.5 2.5 0 0 1-2.5 2.5h-6.292l-.065-.001a1.7 1.7 0 0 1-1.633-1.764c.26-6.793-1.306-9.735-4.51-9.735-3.204 0-4.77 2.942-4.51 9.735l.002.065a1.7 1.7 0 0 1-1.7 1.7H11.5A2.5 2.5 0 0 1 9 39.5V21.395z"
      fillRule="evenodd"
    />
  </svg>
);
