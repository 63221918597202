import React from 'react';
import classNames from 'classnames';
import { PresenceStatus } from '@sbt-web/networking';
import ErrorBoundary from '@sbt-web/error-boundary';
import { UserRating } from '@sbt-web/user-rating';
import { BodyText, Button } from '@sbt-web/ui';

import { PrivateUserBadgeSize, UserBadge } from './components/UserBadge';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { Presence } from './components/Presence';
import { UserName } from './components/UserName';

import styles from './PrivateUserProfileBadge.module.scss';

export interface PrivateUserProfileBadgeProps {
  name: string;
  userId: string;
  pulseEventName: string;
  publishFromText?: string;
  size?: PrivateUserBadgeSize;
  classes?: string[];
  profileUrl?: string;
  picture?: string;
  score?: number;
  presenceStatus?: PresenceStatus;
  presenceText?: string;
  reviewCount?: number;
  showContactButton?: boolean;
}

const getProfileUrl = (userId: string): string => `/utente/${userId}`;

const PrivateUserProfileBadgeComponent = ({
  name,
  publishFromText,
  size,
  classes,
  userId,
  profileUrl,
  pulseEventName,
  picture,
  score,
  presenceStatus,
  presenceText,
  reviewCount,
  showContactButton = false,
}: PrivateUserProfileBadgeProps) => {
  const trackLinkClick = () => {
    getOrCreatePulse()?.queueEvent({
      type: 'Engagement',
      object: {
        '@type': 'UIElement',
        '@id': `sdrn:subito:classified:${userId}:element:${pulseEventName}`,
      },
    });
  };

  return (
    <div className={styles.buttonAndContainer}>
      <div
        className={classNames(
          styles.container,
          {
            [styles.small]: size === PrivateUserBadgeSize.Small,
          },
          classes
        )}
      >
        <UserBadge
          name={name}
          url={profileUrl ?? getProfileUrl(userId)}
          size={size}
          handleClick={trackLinkClick}
          picture={picture}
          presenceStatus={
            size === PrivateUserBadgeSize.Small ? presenceStatus : undefined
          }
        />
        <div className={styles.text_container}>
          <UserName
            name={name}
            url={profileUrl ?? getProfileUrl(userId)}
            handleClick={trackLinkClick}
          />
          <UserRating
            userId={userId}
            pulseInstance={getOrCreatePulse()}
            classes={[styles.rating]}
            score={score}
            reviewCount={reviewCount}
          />
          {publishFromText && (
            <BodyText size="small" classes={[styles.message]}>
              {publishFromText}
            </BodyText>
          )}
          {presenceStatus &&
            presenceText &&
            size !== PrivateUserBadgeSize.Small && (
              <Presence
                presenceText={presenceText}
                presenceStatus={presenceStatus}
              />
            )}
        </div>
      </div>
      {showContactButton && (
        <Button
          classes={[styles.contactButton]}
          design="outline"
          onClick={() => {
            window.dispatchEvent(
              new CustomEvent('subito:AdView:BtnAuthorReply')
            );
          }}
        >
          Contatta
        </Button>
      )}
    </div>
  );
};

export const PrivateUserProfileBadge = (
  props: PrivateUserProfileBadgeProps
) => (
  <ErrorBoundary>
    <PrivateUserProfileBadgeComponent {...props} />
  </ErrorBoundary>
);

export { PrivateUserBadgeSize };
