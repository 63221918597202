import type { AdItem } from '@sbt-web/networking';
import {
  buildAdDetailTarget,
  getAdId,
  getCategory,
  getPublisher,
  getSearchMetaData,
  sendEventToGTM,
} from '@sbt-web/tracking';
import { urnTransformer } from '@sbt-web/utils';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { TrackingSendFormData } from '../components/ContactForm/form';
import type { PhoneContactEvent, SendMessageSuccessEvent } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSubitoDataLayer = (): any => {
  if (!window.subito || !window.subito.dataLayer) {
    throw new Error('Missing Data Layer for AdReply event.');
  }

  return window.subito.dataLayer;
};

export const sendFormButtonClick = (data: TrackingSendFormData): void => {
  getOrCreatePulse()?.queueEvent({
    type: 'Click',
    name: 'modifica o aggiunta elementi al messaggio',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:content:ad_reply:element:add_items',
      label: `cv: ${data.cv} - telefono: ${data.phone} - modifica testo: ${data.editText}`,
    },
  });
};

export const trackContactIntention = (item: AdItem): void => {
  const adId = getAdId(item.urn);
  const { object: ClassifiedAd } = buildAdDetailTarget(item);

  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:classified:${adId}:element:click_on_adreply`,
      elementType: 'button',
    },
    target: {
      ...ClassifiedAd,
      id: adId,
    },
  });
};

export const sendMessageSuccessEvent = ({
  item,
  search,
  isPhoneTrack,
  phone,
  forwardPhone,
}: SendMessageSuccessEvent): void => {
  const { urn, category, advertiser } = item;
  const dataLayer = getSubitoDataLayer();
  const id = !phone
    ? 'no_phone'
    : forwardPhone
      ? 'enable_phone'
      : 'disable_phone';
  const attachments = isPhoneTrack
    ? [
        {
          id,
          type: 'Attachment',
          name: 'phone_number',
          contentType: 'text',
        },
      ]
    : undefined;

  getOrCreatePulse()?.queueEvent({
    type: 'Send',
    search: search ? getSearchMetaData(search.id, search.rank) : undefined,
    intent: 'Contact',
    object: {
      '@type': 'Message',
      '@id': `sdrn:subito:message:${window.location.href}`,
      inReplyTo: {
        '@id': `sdrn:subito:classified:${urn}`,
        '@type': 'ClassifiedAd',
        category: getCategory(category),
        name: dataLayer.page.name,
        adId: urnTransformer(urn)?.adId,
        publisher: getPublisher(advertiser.userId, advertiser.type),
      },
      subject: 'message',
      attachments,
    },
  });
};

export const phoneContactEvent = ({
  item,
  search,
  type,
  name,
  pageType,
}: PhoneContactEvent): void => {
  const { urn, category, advertiser } = item;
  const dataLayer = getSubitoDataLayer();

  getOrCreatePulse()?.queueEvent({
    type,
    name,
    search: search ? getSearchMetaData(search.id, search.rank) : undefined,
    page: {
      '@id': `sdrn:subito:page:${window.location.href}`,
      '@type': 'Page',
      pageType,
    },
    object: {
      '@type': 'PhoneContact',
      inReplyTo: {
        '@id': `sdrn:subito:classified:${urn}`,
        '@type': 'ClassifiedAd',
        name: dataLayer.page.name,
        publisher: getPublisher(advertiser.userId, advertiser.type),
        category: getCategory(category),
      },
    },
  });
};

export const sendMessageSuccessGTMEvent = (): void => {
  sendEventToGTM('reply-message');
};

export const showPhoneContactGTMEvent = (): void => {
  const dataLayer = getSubitoDataLayer();

  sendEventToGTM('shownumber', {
    logged: dataLayer.user !== undefined,
  });
};

export const phoneCallGTMEvent = (): void => {
  sendEventToGTM('reply-call-phone');
};

export const clickRecommendationSearchEvent = (itemCount: number): void => {
  getOrCreatePulse()?.queueEvent({
    type: 'Click',
    object: {
      '@type': 'RecommendationList',
      numItems: itemCount,
    },
    name: 'Click on related pro ads after send message in Addetail',
  });
};

export const viewRecommendationSearchEvent = (itemCount: number): void => {
  getOrCreatePulse()?.queueEvent({
    type: 'View',
    object: {
      '@type': 'RecommendationList',
      numItems: itemCount,
    },
    name: 'View related pro ads after send message in Addetail',
  });
};
