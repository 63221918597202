import React from 'react';

export const Attach = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Attach">
      <path
        fillRule="evenodd"
        d="M12.5287 7.19521L7.52871 12.1952C6.79632 12.9096 5.24167 12.9464 4.46564 12.1894C3.70002 11.4425 3.73941 9.8594 4.46802 9.1415C4.47433 9.13528 5.24544 8.36418 6.98091 6.62868L8.60287 5.0067L9.47152 4.13802C9.84144 3.7681 10.746 3.79499 11.2575 4.19285C11.725 4.55647 11.8444 5.21289 11.5287 5.52854L6.19538 10.8619C6.11455 10.9427 5.8877 10.9447 5.80486 10.8619C5.72201 10.779 5.72403 10.5522 5.80486 10.4714L7.80486 8.47135C8.06521 8.211 8.06521 7.78889 7.80486 7.52854C7.54451 7.26819 7.1224 7.26819 6.86205 7.52854L4.86205 9.52854C4.26424 10.1264 4.25471 11.1973 4.86205 11.8047C5.46939 12.412 6.54038 12.4025 7.13819 11.8047L12.4715 6.47135C13.4122 5.53067 13.126 3.95701 12.0761 3.14038C11.0702 2.358 9.41513 2.30879 8.52871 3.19521L7.66005 4.0639L6.03815 5.68583C4.6107 7.1133 3.53443 8.18956 3.53222 8.19173C2.28307 9.42251 2.22233 11.8637 3.5346 13.1438C4.83881 14.4161 7.21923 14.3597 8.46564 13.1438L13.4715 8.13802C13.7319 7.87767 13.7319 7.45556 13.4715 7.19521C13.2112 6.93486 12.7891 6.93486 12.5287 7.19521Z"
      />
    </g>
  </svg>
);
