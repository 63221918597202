import React from 'react';
import { getAdId } from '@sbt-web/tracking';
import { Button } from '@sbt-web/ui';

import { trackIncreaseVisibilityClick } from './tracking';

import styles from './index.module.scss';

interface IncreaseVisibilityButtonProps {
  isMobile: boolean;
  adUrn: string;
}

export const IncreaseVisibilityButton = ({
  isMobile,
  adUrn,
}: IncreaseVisibilityButtonProps) => {
  const handleClick = async () => {
    const adID = getAdId(adUrn);
    if (adID) trackIncreaseVisibilityClick(adID);

    window.location.assign(
      `${process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL}/annunci/promuovi/${adUrn}`
    );
  };

  return (
    <Button
      onClick={handleClick}
      classes={[styles.increaseVisibilityButton]}
      size={isMobile ? 'large' : 'medium'}
    >
      Aumenta visibilità
    </Button>
  );
};
