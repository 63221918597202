import { TextLink, FullModal } from '@sbt-web/ui';
import classnames from 'classnames';
import React, {
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import css from './style/brandvideo.module.scss';

export interface DialogProps {
  videoId?: string;
  url: string;
  name: string;
  classes?: string[];
}

export const BrandVideo: FunctionComponent<PropsWithChildren<DialogProps>> = ({
  videoId,
  url,
  name,
  classes,
  children,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setShowDialog(true)}
        className={classnames(classes, css['quality-seal-button'])}
      >
        {children}
      </button>

      <FullModal
        open={showDialog}
        title="Informazioni"
        onClose={() => setShowDialog(false)}
        inset
      >
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&showinfo=0&rel=0&modestbranding=1`}
          className={css['quality-seal-video-frame']}
          allowFullScreen
          seamless
          width="740"
          height="360"
          title={`Un video su YouTube che descrive ${name}`}
        />

        <TextLink
          href={url}
          target="_blank"
          classes={[css['quality-seal-dialog-link']]}
          rel="noopener nofollow external"
          size="md"
        >
          {`Maggiori Informazioni su ${name}`}
        </TextLink>
      </FullModal>
    </>
  );
};

BrandVideo.displayName = 'BrandVideo';

export default BrandVideo;
