import { captureException } from '@sentry/nextjs';
import classnames from 'classnames';
import React from 'react';
import QualitySeal from '../../QualitySeal';
import qualitySeals from '../../QualitySeal/quality-seals';
import type { SectionProps } from './section';

type Props = SectionProps & {
  qualitySealId: string;
};

const QualitySealSection = ({ qualitySealId, classes = [] }: Props) => {
  try {
    const qualitySeal = qualitySeals[qualitySealId];

    return (
      <section className={classnames(...classes)}>
        <QualitySeal qualitySeal={qualitySeal} />
      </section>
    );
  } catch (e) {
    captureException(
      new Error(
        `There was an error while rendering the quality seal for ID ${qualitySealId}: ${e}`
      ),
      { level: 'error' }
    );

    return null;
  }
};

export default QualitySealSection;
