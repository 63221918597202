import { AdsAdDetailsMSiteBlock } from '@client/components/Adv/AdSense/adDetails/AdDetailsMSiteBlock';
import AdBuy from '@client/components/Detail/AdBuy';
import { AdReply } from '@client/components/Detail/AdReply';
import AdvertiserInfo from '@client/components/Detail/AdvertiserInfo';
import { IncreaseVisibilityButton } from '@client/components/Detail/IncreaseVisibilityButton';
import { ReplyTime } from '@client/components/Detail/TrustInfo/ReplyTime';
import UsernameDialog from '@client/components/Detail/UsernameDialog';
import type { RootState } from '@reducers/index';
import { setPreventPopstate } from '@reducers/system';
import { setDialogInfo, type UserState } from '@reducers/user';
import { LoginTemplate, type PublicUser } from '@sbt-web/auth';
import ErrorBoundary from '@sbt-web/error-boundary';
import { useViewport } from '@sbt-web/hooks';
import {
  AdItem,
  FeatureURI,
  ProfileQueryData,
  Shop,
  TrustProfileInfo,
} from '@sbt-web/networking';
import {
  getTransactionFlows,
  TransactionFlow,
} from '@shared/tools/transaction/utils';
import classnames from 'classnames';
import React, { useState } from 'react';
import { batch, connect } from 'react-redux';
import { AdReplyButtons } from '../../AdReply/components/AdReplyButtons';
import { AdReplyEvent } from '../../AdReply/events';
import { getShowPhone } from '@tools/phoneNumber/utils';
import ownClasses from './style/advertiser-info-section.module.scss';

interface OwnProps {
  classes?: string[];
  item: AdItem;
  shop: Shop | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  makeAnOfferABTestVariant?: 'A' | 'B';
}

interface StateProps {
  user: UserState;
}

interface DispatchProps {
  requestLogin: (cb: (user: PublicUser) => void) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

export const AdvertiserInfoSection = ({
  classes = [],
  shop,
  advertiserProfile,
  item,
  trustInfo,
  makeAnOfferABTestVariant = 'A',
  requestLogin,
  user,
}: Props) => {
  const { isMobile } = useViewport();
  const [isContactFormOpened, setIsContactFormOpened] = useState(false);
  const userId = user.data?.id ?? '';

  const transactionFlows = getTransactionFlows(
    item.features,
    item.advertiser,
    userId
  );
  const isBuyNowVisible = transactionFlows.includes(TransactionFlow.BuyNow);

  const userIsSeller = userId === item.advertiser.userId;
  const transactionStatus: string | undefined =
    item.features[FeatureURI.TransactionStatus]?.values[0].value;
  const isSold = transactionStatus != undefined;
  const thisIsMySoldAd = isSold && userIsSeller;
  const thereIsPlaceForContactButton = transactionFlows.length < 2;
  const isOnlyOneTransactionFlowsVisible = transactionFlows.length === 1;
  const showPhone = getShowPhone(item, userId);
  const shouldShowReplyTimeText =
    !userIsSeller && trustInfo?.replyTimeText != null;

  const handleContactButtonClick = () => {
    window.dispatchEvent(new CustomEvent(AdReplyEvent.ContactButtonClick));
  };

  const handleMakeYourPriceButtonClick = () => {
    window.dispatchEvent(
      new CustomEvent(AdReplyEvent.MakeYourPriceButtonClick)
    );
  };

  return (
    <>
      <section className={classnames(...classes)}>
        <div className={classnames(ownClasses['seller-info-content'])}>
          <AdvertiserInfo
            item={item}
            shop={shop}
            advertiserProfile={advertiserProfile}
            trustInfo={trustInfo}
            showContactButton={isBuyNowVisible}
            makeAnOfferABTestVariant={makeAnOfferABTestVariant}
          />
          {!isMobile && !thisIsMySoldAd && (
            <div
              className={classnames(
                'sticky-cta-bottom-anchor',
                ownClasses['action-buttons']
              )}
            >
              {!isContactFormOpened && (
                <div className={classnames(ownClasses['buttons-box'])}>
                  {userIsSeller ? (
                    <IncreaseVisibilityButton
                      isMobile={isMobile}
                      adUrn={item.urn}
                    />
                  ) : (
                    <>
                      {trustInfo?.replyTimeText ? (
                        <ReplyTime text={trustInfo?.replyTimeText} />
                      ) : null}
                      {thereIsPlaceForContactButton && (
                        <AdReplyButtons
                          adUrn={item.urn}
                          isMobile={isMobile}
                          onContactButtonClick={handleContactButtonClick}
                          contactAsSecondaryButton={
                            isOnlyOneTransactionFlowsVisible
                          }
                          showPhone={showPhone}
                          item={item}
                          makeAnOfferABTestVariant={makeAnOfferABTestVariant}
                          onMakeYourPriceButtonClick={
                            handleMakeYourPriceButtonClick
                          }
                        />
                      )}
                      <AdBuy item={item} />
                    </>
                  )}
                </div>
              )}
              <AdReply
                item={item}
                isMobile={false}
                requestLogin={() => {
                  return new Promise((resolve) => {
                    requestLogin(resolve);
                  });
                }}
                userId={userId}
                onStatusDialogChanged={setIsContactFormOpened}
                advertiserProfile={advertiserProfile}
                shop={shop}
              />
            </div>
          )}
          <AdsAdDetailsMSiteBlock
            isMobile={isMobile}
            query={item.subject}
            listingCategory={item.category.id}
          />
        </div>
      </section>

      {isMobile && !thisIsMySoldAd && (
        <section
          className={classnames(
            'sticky-cta-bottom-anchor',
            ownClasses['stickiness'],
            {
              [ownClasses['with-reply-time']]: shouldShowReplyTimeText,
            },
            ...classes
          )}
        >
          <div className={classnames(ownClasses['buttons-box'])}>
            {userIsSeller ? (
              <IncreaseVisibilityButton isMobile={isMobile} adUrn={item.urn} />
            ) : (
              <>
                {thereIsPlaceForContactButton && (
                  <AdReplyButtons
                    adUrn={item.urn}
                    isMobile={isMobile}
                    onContactButtonClick={handleContactButtonClick}
                    contactAsSecondaryButton={isOnlyOneTransactionFlowsVisible}
                    showPhone={showPhone}
                    item={item}
                    makeAnOfferABTestVariant={makeAnOfferABTestVariant}
                    onMakeYourPriceButtonClick={handleMakeYourPriceButtonClick}
                  />
                )}
                <AdBuy item={item} />
              </>
            )}
          </div>
          {shouldShowReplyTimeText && (
            <ReplyTime text={trustInfo?.replyTimeText} />
          )}
          <AdReply
            item={item}
            isMobile={true}
            requestLogin={() => {
              return new Promise((resolve) => {
                requestLogin(resolve);
              });
            }}
            userId={userId}
            onStatusDialogChanged={setIsContactFormOpened}
            advertiserProfile={advertiserProfile}
            shop={shop}
          />
        </section>
      )}

      <UsernameDialog />
    </>
  );
};

const WrapperComponent = (props: Props) => (
  <ErrorBoundary>
    <AdvertiserInfoSection {...props} />
  </ErrorBoundary>
);

const mapStateToProps = ({ user }: RootState): StateProps => ({ user });

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  (dispatch) => ({
    requestLogin: (cb) => {
      batch(() => {
        dispatch(setDialogInfo(true, LoginTemplate.AdReply, cb));
        dispatch(setPreventPopstate(true));
      });
    },
  })
)(WrapperComponent);
