import classnames from 'classnames';
import { CategoryId } from '@sbt-web/networking';
import React from 'react';
import SafetyTips from '../../SafetyTips';
import { SectionProps } from './section';

type Props = SectionProps & {
  category: CategoryId;
  urn: string;
};

export function SafetySection({ classes = [], category, urn }: Props) {
  return (
    <section className={classnames(...classes, 'safety-component')}>
      <SafetyTips category={category} adUrn={urn} />
    </section>
  );
}

export default SafetySection;
