import type {
  AdItem,
  ProfileQueryData,
  Shop,
  TrustProfileInfo,
} from '@sbt-web/networking';
import React from 'react';
import { AdvertiserInfoPrivate } from './AdvertiserInfoPrivate';
import { AdvertiserType } from '@sbt-web/networking';
import { AdvertiserInfoPro } from './AdvertiserInfoPro';

interface Props {
  item: AdItem;
  shop: Shop | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  showContactButton?: boolean;
  makeAnOfferABTestVariant?: 'A' | 'B';
}

const AdvertiserInfo: React.FunctionComponent<Props> = ({
  shop,
  advertiserProfile,
  trustInfo,
  item,
  showContactButton = false,
  makeAnOfferABTestVariant = 'A',
}) => {
  switch (item.advertiser.type) {
    case AdvertiserType.Private:
    case AdvertiserType.Company:
      return (
        <AdvertiserInfoPrivate
          advertiserProfile={advertiserProfile}
          trustInfo={trustInfo}
          item={item}
          showContactButton={showContactButton}
        />
      );
    case AdvertiserType.ImpresaPiu:
      return (
        <AdvertiserInfoPro
          shop={shop}
          advertiserProfile={advertiserProfile}
          item={item}
          makeAnOfferABTestVariant={makeAnOfferABTestVariant}
        />
      );
    default:
      return null;
  }
};

export default AdvertiserInfo;
