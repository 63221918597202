import { Headline6 } from '@sbt-web/ui';
import classnames from 'classnames';
import React from 'react';
import FeatureList, { Feature } from '../../FeatureList';
import type { SectionProps } from './section';
import ownClasses from './style/feature-list-section.module.scss';

interface OwnProps {
  sectionTitle?: string;
  features: Feature[];
}

type Props = SectionProps & OwnProps;

export function FeatureListSection({
  sectionTitle = 'Dati Principali',
  features,
  classes = [],
}: Props) {
  return (
    <section className={classnames(...classes, 'main-data')}>
      <Headline6 element="h2" classes={[ownClasses['section-title']]}>
        {sectionTitle}
      </Headline6>

      <FeatureList features={features} />
    </section>
  );
}

export default FeatureListSection;
