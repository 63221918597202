import { getOrCreatePulse } from '@tools/tracking/utils';

export const trackIncreaseVisibilityClick = (adID: string): void =>
  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:classified:${adID}:element:aumenta-visibilità-button`,
      elementType: 'button',
    },
  });
