import { getDetailSearchId } from '@client/bridges/navigation-data';
import { SearchData } from './types';

export const getSearchValue = (): SearchData | undefined => {
  const searchId = getDetailSearchId();

  if (!searchId) {
    return undefined;
  }

  return {
    id: searchId,
    rank: window.subito?.dataLayer?.ads?.[0]?.rank,
  };
};
