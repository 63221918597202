import React from 'react';
import { BodyText, Caption, Headline6, Overline } from '@sbt-web/ui';

import { DiscountedCost } from './DiscountedCost';
import type {
  CostWithDiscount,
  ShippingCosts,
  SubitoShipping,
} from 'server/detail-requests/ShippingCost';

import classes from './index.module.scss';
import {
  CourierIcon,
  DeliveryPointShippingIcon,
  HomeShippingIcon,
} from './Icons';

type HomeShippingRowProps = {
  cost: CostWithDiscount;
};

const HomeShippingRow = ({ cost }: HomeShippingRowProps) => (
  <div className={classes['shipping-cost-wrapper']}>
    <div className={classes['icon-wrapper']}>
      <HomeShippingIcon />
    </div>

    <div className={classes['shipping-cost']}>
      <div className={classes['name-and-price']}>
        <BodyText weight="semibold">Consegna a domicilio</BodyText>
        <DiscountedCost costWithDiscount={cost} />
      </div>
      <Caption classes={[classes['shipping-text']]} size="small" weight="book">
        Il pacco verrà recapitato all&apos;indirizzo da te scelto
      </Caption>
    </div>
  </div>
);

type TuttoSubitoShippingProps = {
  costs: SubitoShipping;
};

const TuttoSubitoShipping = ({ costs }: TuttoSubitoShippingProps) => (
  <>
    {costs.minCostToDeliveryPoint && (
      <>
        <div className={classes['shipping-cost-wrapper']}>
          <div className={classes['icon-wrapper']}>
            <DeliveryPointShippingIcon />
          </div>
          <div className={classes['shipping-cost']}>
            <div className={classes['name-and-price']}>
              <div className={classes['cost-with-icon']}>
                <BodyText weight="semibold">Punto di ritiro</BodyText>
                <img
                  id={classes['leaf-icon']}
                  src="/static/img/tuttosubito/leaf.svg"
                  width="16"
                  height="16"
                  alt="Una scelta sostenibile"
                  loading="lazy"
                  decoding="async"
                />
              </div>
              <DiscountedCost costWithDiscount={costs.minCostToDeliveryPoint} />
            </div>
            <Caption
              classes={[classes['shipping-text']]}
              size="small"
              weight="book"
            >
              Ritiro del pacco presso un negozio o un locker
            </Caption>
          </div>
        </div>
        <Caption
          size="small"
          weight="book"
          classes={[classes['shipping-option-separator']]}
        >
          Oppure
        </Caption>
      </>
    )}
    <HomeShippingRow cost={costs.costToHome} />
  </>
);

type ShippingMethodsProps = {
  costs: ShippingCosts;
};

export const ShippingMethods = ({ costs }: ShippingMethodsProps) => (
  <>
    <Headline6 element="h2" classes={[classes.title]}>
      Modalità di consegna
    </Headline6>

    <BodyText size="small" classes={[classes.subtitle]}>
      {costs.type === 'tuttosubito' && costs.minCostToDeliveryPoint
        ? 'In fase di acquisto potrai scegliere tra queste opzioni'
        : "In fase di acquisto potrai inserire l'indirizzo"}
    </BodyText>

    <div className={classes['shipping-options']}>
      {costs.type === 'self' ? (
        <HomeShippingRow cost={{ partial: false, cost: costs.cost }} />
      ) : (
        <TuttoSubitoShipping costs={costs} />
      )}
    </div>

    {costs.type === 'self' ? (
      <Overline classes={[classes['shipping-type']]}>
        Spedizione gestita dal venditore
      </Overline>
    ) : (
      <>
        <Overline classes={[classes['shipping-type']]}>
          Spedizione gestita da Subito
        </Overline>

        <div className={classes['shipping-icons-container']}>
          {costs.couriers.map((carrierInfo) => (
            <CourierIcon
              key={carrierInfo.id}
              id={carrierInfo.id}
              name={carrierInfo.name}
            />
          ))}
        </div>
      </>
    )}
  </>
);
