import React from 'react';
import { Button } from '@sbt-web/ui';

import classes from '../index.module.scss';

export type MakeYourPriceButtonProps = {
  onClick: () => void;
  isSecondary?: boolean;
};

export const MakeYourPriceButton = ({
  onClick,
  isSecondary = false,
}: MakeYourPriceButtonProps) => (
  <Button
    design={isSecondary ? 'outline' : undefined}
    onClick={onClick}
    classes={[classes.contactButton]}
    size="large"
  >
    Fai il tuo prezzo
  </Button>
);
