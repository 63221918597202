import React from 'react';
import classnames from 'classnames';

import styles from './index.module.scss';

interface ReplyTimeProps {
  text: string;
}

export const ReplyTime = ({ text }: ReplyTimeProps) => {
  return (
    <p className={classnames('caption small book', styles['reply-time-text'])}>
      {text}
    </p>
  );
};
