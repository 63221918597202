import React from 'react';
import { FullModal } from '@sbt-web/ui';
import classnames from './index.module.scss';

const renderIframe = (
  fullscreen: boolean,
  opened: boolean,
  flowUrl: string
): React.ReactNode => {
  const flowOrigin = flowUrl && new URL(flowUrl).origin;
  const styles: React.CSSProperties = { display: 'block', width: '100%' };

  if (fullscreen) {
    styles.height = 'calc(100vh - 82px)';
  } else {
    styles.height = '80vh';
  }

  if (!opened) {
    styles.display = 'none';
  }

  return (
    <iframe
      className={classnames['iframe']}
      title="flowdialog-iframe"
      key={flowUrl}
      src={flowUrl}
      style={styles}
      frameBorder="0"
      scrolling="auto"
      allow={`geolocation ${flowOrigin}; payment ${flowOrigin};`}
    />
  );
};

export interface FlowDialogProps {
  fullscreen: boolean;
  opened: boolean;
  onCloseIntent: () => void;
  flowUrl: string;
}
export const FlowDialog = ({
  fullscreen,
  opened,
  onCloseIntent,
  flowUrl,
}: FlowDialogProps) => {
  const iframe = renderIframe(fullscreen, opened, flowUrl);

  React.useEffect(() => {
    const cb = (event: { data?: { close?: boolean } }) => {
      if (event.data?.close) {
        onCloseIntent();
      }
      return false;
    };
    window.addEventListener('message', cb, false);

    return () => window.removeEventListener('message', cb);
  }, [onCloseIntent]);

  return (
    <FullModal
      title="Acquista a distanza"
      open={opened}
      onClose={onCloseIntent}
      size={{ w: '640px', h: '100%' }}
      overflowHidden
      inset
    >
      <div className={classnames['iframe-container']}>{iframe}</div>
    </FullModal>
  );
};
