import type { CategoryId } from '@sbt-web/networking';
import React from 'react';
import css from './Placeholder.module.scss';

interface Props {
  categoryId: CategoryId;
}

const Placeholder: React.FunctionComponent<Props> = ({ categoryId }) => {
  return (
    <figure className={css.placeholder}>
      <img
        // The placeholder is decorative
        alt=""
        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/categories/${categoryId}.svg`}
        width="96"
        height="96"
        decoding="async"
      />
    </figure>
  );
};

export default Placeholder;
