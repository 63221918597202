import type { InfocarProperty } from '@sbt-web/networking';
import { BodyText, FullModal, Headline6 } from '@sbt-web/ui';
import classnames from 'classnames';
import React, { useState, type FunctionComponent } from 'react';
import css from './index.module.scss';

export interface Props {
  infocarData: InfocarProperty[] | null;
}

const Disclaimer: FunctionComponent = () => (
  <article>
    <img
      src="/static/img/infocar/badge.svg"
      width="100"
      height="27"
      alt=""
      decoding="async"
      className={css['disclaimer-badge']}
    />
    <BodyText classes={[css['disclaimer-clause']]}>
      I dati delle schede tecniche sono compilati in base alle informazioni
      ufficiali fornite dalla <b>Banca Dati Quattroruote</b>, basata sul Codice
      Infocar.
    </BodyText>
    <BodyText classes={[css['disclaimer-clause']]}>
      Ogni veicolo viene identificato in base a Marca / Modello / Allestimento /
      Anno e Mese di immatricolazione forniti dal venditore in fase di
      inserimento annuncio.
    </BodyText>
    <BodyText classes={[css['disclaimer-clause']]}>
      La fonte dati Quattroruote Professional è costantemente aggiornata sulla
      base delle schede di omologazione provenienti in via ufficiale dai
      costruttori e/o dai relativi importatori.
    </BodyText>
  </article>
);

const Infocar: React.FunctionComponent<Props> = (props) => {
  const [showDialog, showDialogSet] = useState(false);

  if (props.infocarData == null) {
    return null;
  }

  return (
    <>
      <Headline6 element="h2" classes={[css.title]}>
        Scheda Tecnica
      </Headline6>
      <button
        type="button"
        aria-label="Informazioni sulla fonte dei dati"
        onClick={() => showDialogSet(true)}
        className={css.dialogOpener}
      >
        <BodyText element="span">Fonte dati</BodyText>
        <img
          src="/static/img/infocar/badge.svg"
          width="100"
          height="27"
          alt="Quattroruote professional"
          loading="lazy"
          decoding="async"
        />
        <img
          src="/static/img/infocar/help.svg"
          width="20"
          height="20"
          alt=""
          loading="lazy"
          decoding="async"
        />
      </button>

      {props.infocarData.map((property) => (
        <section key={property.title} className={css.infocarSection}>
          <h3 className={css.infocarSectionHeading}>
            <BodyText classes={[css.title]} element="span" weight="book">
              {property.title}
            </BodyText>
          </h3>
          <dl className={css.infocarFeatureList}>
            {property.features.map((feature) => (
              <div key={feature.label} className={css.infocarFeatureListItem}>
                <dt
                  className={classnames(
                    css.infocarFeatureElement,
                    css.infocarFeatureLabel
                  )}
                >
                  <BodyText>{feature.label}</BodyText>
                </dt>
                <dd
                  className={classnames(
                    css.infocarFeatureElement,
                    css.infocarFeatureValue
                  )}
                >
                  <BodyText>{feature.value}</BodyText>
                </dd>
              </div>
            ))}
          </dl>
        </section>
      ))}

      <FullModal
        title="Fonte dati"
        open={showDialog}
        onClose={() => showDialogSet(false)}
      >
        <Disclaimer />
      </FullModal>
    </>
  );
};

export default Infocar;
