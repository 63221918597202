import { type ProfileQueryData, type Shop } from '@sbt-web/networking';
import { DEFAULT_ADVERTISER_NAME } from '@shared/constants';
import { AdvertiserType } from '@tools/search/values';

export function isCompany(advertiserType: number | AdvertiserType): boolean {
  return (
    advertiserType === AdvertiserType.ImpresaPiu ||
    advertiserType === AdvertiserType.Company
  );
}

export const isPro = (advertiserType: number | AdvertiserType): boolean =>
  advertiserType === AdvertiserType.ImpresaPiu;

export function getAdvertiserName(
  advertiserType: number,
  shop: Shop | null,
  advertiserProfile: ProfileQueryData | null
): string {
  const name = advertiserProfile?.username ?? DEFAULT_ADVERTISER_NAME;

  if (isPro(advertiserType)) {
    return shop?.name ?? name;
  }

  return name;
}
