import React, { useState, useEffect, useContext } from 'react';
import { AdItem } from '@sbt-web/networking';
import { getPublicUser } from '@sbt-web/auth';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';

import { MakeYourPriceButton } from './MakeYourPriceButton';
import { ContactButton } from './ContactButton';
import { PhoneButton } from './PhoneButton';
import { getSearchValue } from '../../utils';
import classes from './index.module.scss';

export type AdReplyButtonsProps = {
  isMobile: boolean;
  onContactButtonClick: () => void;
  adUrn: string;
  showPhone: boolean;
  item: AdItem;
  contactAsSecondaryButton?: boolean;
  makeAnOfferABTestVariant?: 'A' | 'B';
  onMakeYourPriceButtonClick: () => void;
};

const DEFAULT_AB_VARIANT = 'A';
const HOUSTON_DESKTOP_CTA_TEST_ALIAS = 'subito-ad-detail-cta-swap-desktop';
const HOUSTON_MOBILE_CTA_TEST_ALIAS = 'subito-ad-detail-cta-swap-mobile';

export const AdReplyButtons = ({
  isMobile,
  onContactButtonClick,
  contactAsSecondaryButton,
  adUrn,
  showPhone,
  item,
  makeAnOfferABTestVariant = 'A',
  onMakeYourPriceButtonClick,
}: AdReplyButtonsProps) => {
  const searchValue = getSearchValue();

  /*
   * AB mobile/desktop houston test: swap of CTA positions for not logged users
   */
  const [contactTouchPointVariant, setContactTouchPointVariant] = useState<
    'A' | 'B'
  >(DEFAULT_AB_VARIANT);
  const { optimizely } = useContext(OptimizelySubitoContext);
  const user = getPublicUser();

  useEffect(() => {
    if (!showPhone || user?.id) return;

    const houstonCtaTestAlias = isMobile
      ? HOUSTON_MOBILE_CTA_TEST_ALIAS
      : HOUSTON_DESKTOP_CTA_TEST_ALIAS;

    const setVariant = async () => {
      await optimizely?.onReady();
      const variant =
        optimizely?.activate(houstonCtaTestAlias) ?? DEFAULT_AB_VARIANT;
      setContactTouchPointVariant(variant as 'A' | 'B');
    };

    setVariant();
  }, [optimizely, user?.id, item, showPhone, isMobile]);

  if (contactTouchPointVariant === 'B') {
    return (
      <div className={classes.switchedTouchPoints}>
        <ContactButton
          isSecondary={true}
          onClick={onContactButtonClick}
          showLabel={false}
          showIcon={true}
        />

        {showPhone && (
          <PhoneButton
            adUrn={adUrn}
            isMobile={isMobile}
            search={searchValue}
            item={item}
            buttonLabelDesktop="Mostra numero"
            buttonLabelMobile="Mostra numero"
            buttonDesign="solid"
            buttonSize="large"
            pulseEvent={{
              name: 'Mostra Numero',
              pageType: 'ad_detail',
            }}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {showPhone && (
        <PhoneButton
          adUrn={adUrn}
          isMobile={isMobile}
          search={searchValue}
          item={item}
          buttonDesign="outline"
          buttonSize="large"
          pulseEvent={{
            name: 'Mostra Numero',
            pageType: 'ad_detail',
          }}
        />
      )}
      {makeAnOfferABTestVariant === 'B' ? (
        <MakeYourPriceButton
          isSecondary={contactAsSecondaryButton}
          onClick={onMakeYourPriceButtonClick}
        />
      ) : (
        <ContactButton
          isSecondary={contactAsSecondaryButton}
          onClick={onContactButtonClick}
        />
      )}
    </>
  );
};
