import * as React from 'react';
import { getCookie } from '@sbt-web/utils';
import ErrorBoundary from '@sbt-web/error-boundary';

import { FavoriteCounter } from '@sbt-web/ad-favorite-counter';
import '@sbt-web/ad-favorite-counter/dist/index.css';

/**
 * when user save or unsave favorites ewe stored the value on a cookie for 5minutes.
 * In this way we workaround the page cache.
 * The cookie will not be longer necessary when the page will have, for example, the "max-age" set to zero
 */
const COOKIE_FAVORITE_COUNTER_CACHE = 'COOKIE_FAVORITE_COUNTER_CACHE';
const EXPIRE_DATE = 300;

export const getPathName = function () {
  return window.location.pathname;
};

export const updateCookie = function (value: number): string {
  const cookieValue = `${COOKIE_FAVORITE_COUNTER_CACHE}=${value};path=${getPathName()};max-age=${EXPIRE_DATE}`;
  document.cookie = cookieValue;
  return cookieValue;
};

interface Props {
  value?: number;
}

export function useFavoriteCounter(
  initFavoriteCounter?: number
): [number | undefined, () => void, () => void] {
  const [usersFollowAd, setUsersFollowAd] = React.useState(initFavoriteCounter);

  /**
   * Read the cookie and apply it to value
   */
  React.useEffect(() => {
    const value = getCookie(COOKIE_FAVORITE_COUNTER_CACHE);
    if (value) {
      const castedValue = Number(value);
      if (!isNaN(castedValue)) {
        setUsersFollowAd(castedValue);
      }
    }
  }, []);

  const onSaveFavorite = () => {
    if (usersFollowAd !== undefined) {
      const newValue = usersFollowAd + 1;
      setUsersFollowAd(newValue);
      updateCookie(newValue);
    }
  };
  const onUnsaveFavorite = () => {
    if (usersFollowAd && usersFollowAd > 0) {
      const newValue = usersFollowAd - 1;
      setUsersFollowAd(newValue);
      updateCookie(newValue);
    }
  };
  return [usersFollowAd, onSaveFavorite, onUnsaveFavorite];
}

/**
 * FavoriteCounter component is the domain of "User Lister" tribe,
 * thus we encapsulated it into the ErrorBoundary component, to avoid problem to the
 * rest of the page
 */
export const FavoriteCounterWrapper = ({ value }: Props) => {
  return value !== undefined ? (
    <ErrorBoundary>
      <FavoriteCounter value={value} />
    </ErrorBoundary>
  ) : null;
};
FavoriteCounterWrapper.displayName = 'FavoriteCounter';
