import type { DLAd } from '@client/bridges/DataLayer';
import { getDetailSearchId, getUrnRank } from '@client/bridges/navigation-data';
import { slugify, urnTransformer } from '@sbt-web/utils';
import { PULSE_SCHEMA_VERSION } from '@tools/tracking/constants';
import {
  transformAdType,
  transformCategory,
  transformLocality,
  transformPostalAddress,
  transformPublisher,
} from '@tools/tracking/transformer';
import { buildSearchWithRank } from '@tools/tracking/transformer/SearchTransformer';
import {
  detectAdBlocker,
  getDataLayer,
  getOrCreatePulse,
} from '@tools/tracking/utils';

async function sendViewClassifiedAdEvent(): Promise<void> {
  // On the detail there's only one ad in the array
  const rawAd = getDataLayer().ads[0];
  const publisher = transformPublisher(rawAd.advertiser);

  const searchId = getDetailSearchId();
  const rank = getUrnRank(rawAd.urn);
  const { adId, contentId } = urnTransformer(rawAd.urn) ?? {};

  const canBuildSearchMetadata = searchId != null && rank != null;

  const data = {
    type: 'View',
    object: {
      type: 'ClassifiedAd',
      id: rawAd.urn,
      name: document.title,
      category: transformCategory(rawAd.category),
      adId: parseInt(adId ?? '0'),
      contentId,
      location: transformPostalAddress(rawAd.geo),
      publisher,
      'spt:custom': {
        name: document.title,
        url: window.location.href,
        title: rawAd.subject,
        inLanguage: 'it_IT',
        region: slugify(rawAd.geo.region?.name ?? ''),
        locality: transformLocality(rawAd.geo),
        price: getPrice(rawAd),
        currency: 'EUR',
        adType: transformAdType(rawAd.type),
        publisherType: publisher.accountType,
      },
    },
    ...(canBuildSearchMetadata
      ? { search: buildSearchWithRank(searchId, rank) }
      : {}),
    adBlock: await detectAdBlocker(),
    schema: PULSE_SCHEMA_VERSION,
  };

  getOrCreatePulse()?.queueEvent(data);
}

function getPrice(ad: DLAd): string {
  const fs = ad.features;
  let price = '';
  if ('price' in fs) {
    if ('id' in fs.price) {
      price = fs.price.id;
    } else {
      price = fs.price;
    }
  } else if ('/price' in fs) {
    if ('id' in fs['/price']) {
      price = fs['/price'].id;
    } else {
      price = fs['/price'];
    }
  }

  return (price ?? '').replace(/\./g, '');
}

export { sendViewClassifiedAdEvent };
