import type { ItemMap } from '@sbt-web/networking';
import { useIsVisible } from '@sbt-web/hooks';
import { Headline6 } from '@sbt-web/ui';
import { getOrCreatePulse } from '@tools/tracking/utils';
import {
  buildTrackingFilterData,
  buildTrackingPOIData,
  buildTrackingViewData,
  buildTrackingFullScreenData,
  buildTrackingZoomData,
} from '@tools/tracking/yatmoMapTracking/buildTrackingData';
import Script from 'next/script';
import React from 'react';
import classes from './YatmoMap.module.scss';

interface Props {
  map?: ItemMap;
}

const observerOptionsTop = {
  threshold: 0.01,
};
const observerOptionsMiddle = {
  threshold: 0.5,
};

const YatmoMap = ({ map }: Props) => {
  const element = React.useRef<HTMLDivElement>(null);
  const [isVisibleTop, setElementTop, unobserveTop] = useIsVisible({
    observerOptions: observerOptionsTop,
  });
  const [isVisibleMiddle, setElementMiddle, unobserveMiddle] = useIsVisible({
    observerOptions: observerOptionsMiddle,
  });
  const handleYatmoMapClick = (event: React.MouseEvent): void => {
    const eventTarget = event.target as HTMLElement;
    // Click on the map's button filters
    if (
      eventTarget instanceof HTMLElement &&
      eventTarget.parentElement?.id === 'poisFilterContainer'
    ) {
      switch (eventTarget.id) {
        case 'bt-kids':
          getOrCreatePulse()?.queueEvent(buildTrackingFilterData('Educazione'));
          break;
        case 'bt-transport':
          getOrCreatePulse()?.queueEvent(buildTrackingFilterData('Trasporti'));
          break;
        case 'bt-motorways':
          getOrCreatePulse()?.queueEvent(buildTrackingFilterData('Autostrade'));
          break;
        case 'bt-shops':
          getOrCreatePulse()?.queueEvent(buildTrackingFilterData('Shopping'));
          break;
        default:
          break;
      }
    }
    // Click on the map's POI
    else if (eventTarget.dataset.poitypeid) {
      const poiTypeId = eventTarget.dataset.poitypeid;
      const poiId = eventTarget.id;
      switch (poiTypeId[3]) {
        case '1':
          getOrCreatePulse()?.queueEvent(
            buildTrackingPOIData('Educazione', poiId)
          );
          break;
        case '2':
          poiTypeId[7] === '5'
            ? getOrCreatePulse()?.queueEvent(
                buildTrackingPOIData('Autostrade', poiId)
              )
            : getOrCreatePulse()?.queueEvent(
                buildTrackingPOIData('Trasporti', poiId)
              );
          break;
        case '3':
          getOrCreatePulse()?.queueEvent(
            buildTrackingPOIData('Shopping', poiId)
          );
          break;
        default:
          break;
      }
    }
    // Click on the map's FullScreen button
    else if (eventTarget.parentElement?.ariaLabel?.includes('fullscreen')) {
      eventTarget.parentElement.className?.includes('fullscreen')
        ? getOrCreatePulse()?.queueEvent(buildTrackingFullScreenData('Open'))
        : getOrCreatePulse()?.queueEvent(buildTrackingFullScreenData('Close'));
    }
    // Click on the map's Zoom buttons
    else if (eventTarget.parentElement?.ariaLabel?.includes('Zoom')) {
      eventTarget.parentElement.className?.includes('zoom-in')
        ? getOrCreatePulse()?.queueEvent(buildTrackingZoomData('In'))
        : getOrCreatePulse()?.queueEvent(buildTrackingZoomData('Out'));
    }
  };
  React.useEffect(() => {
    if (element.current !== null) {
      setElementTop(element.current);
      if (isVisibleTop) {
        getOrCreatePulse()?.queueEvent(buildTrackingViewData('Top'));
        unobserveTop();
      }
    }
  }, [isVisibleTop, setElementTop, unobserveTop]);

  React.useEffect(() => {
    if (element.current !== null) {
      setElementMiddle(element.current);
      if (isVisibleMiddle) {
        getOrCreatePulse()?.queueEvent(buildTrackingViewData('Middle'));
        unobserveMiddle();
      }
    }
  }, [isVisibleMiddle, setElementMiddle, unobserveMiddle]);

  if (!map?.longitude || !map?.latitude) {
    return null;
  }
  return (
    <>
      <Script id="yatmo-config">
        {`const yatmoConfig = {
            licenseKey: '${process.env.NEXT_PUBLIC_YATMO_WEB_KEY}',
            language: 'IT',
            country: 'IT',
            container: 'map',
            center: [${map.longitude}, ${map.latitude}],
            zoom: 14,
            mapStyle: 3,
            fullScreenButton: true,
            useSmallButtons:false
          }

          window.LoadMap = (map) => {
            new mapboxgl.Marker({ color: 'red' })
              .setLngLat(map.getCenter())
              .addTo(map);
          };`}
      </Script>
      <Script src="https://map.yatmo.com/script.js" />

      <Headline6>Posizione</Headline6>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <div
        id="map"
        className={classes.frame}
        ref={element}
        onClick={handleYatmoMapClick}
        role="application"
      />
    </>
  );
};

export default YatmoMap;
