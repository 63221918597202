import { Divider } from '@sbt-web/ui';
import type { ItemMainFeature } from '@sbt-web/utils';
import classNames from 'classnames';
import React from 'react';
import type { SectionProps } from './section';
import ownClasses from './style/main-data.module.scss';

interface Props {
  features: ItemMainFeature[];
}

const MainData = ({ features }: Props) => {
  const featureList = features.map(
    ({ label = '', placeholder = null, iconURL }) => {
      const hasLabel = label !== '';
      const hasPlaceholder = placeholder !== null;

      if (!hasLabel && !hasPlaceholder) {
        return null;
      }

      const displayLabel = hasLabel ? label : `- (${placeholder})`;

      return (
        <div className={ownClasses['main-feature']} key={iconURL}>
          <img
            src={iconURL}
            alt=""
            width="16"
            height="16"
            loading="lazy"
            decoding="async"
          />
          <p
            className={classNames('caption', {
              [ownClasses.label]: hasLabel,
              [ownClasses.placeholder]: !hasLabel && hasPlaceholder,
            })}
          >
            {displayLabel}
          </p>
        </div>
      );
    }
  );

  return (
    <div className={ownClasses['main-features-container']}>{featureList}</div>
  );
};

const MainDataSection = ({ features, classes = [] }: Props & SectionProps) => {
  return (
    <section className={classNames(...classes)}>
      <h6 className={classNames('headline-6', ownClasses.title)}>
        Dati principali
      </h6>
      <MainData features={features} />

      <Divider spacing="null" />
    </section>
  );
};

export default MainDataSection;
