import classnames from 'classnames';
import React from 'react';
import BottomBar from '../../BottomBar';
import type { AdItem } from '@sbt-web/networking';

interface BottomSectionProps {
  classes?: string[];
  item: AdItem;
}

const BottomSection: React.FunctionComponent<BottomSectionProps> = ({
  classes = [],
  item,
}) => {
  return (
    <section className={classnames('detail-component', ...classes)}>
      <BottomBar item={item} />
    </section>
  );
};
export default BottomSection;
