import { TextArea } from '@sbt-web/ui';
import React from 'react';

interface MessageProps {
  message: string;
  errorMessage?: string;
  onChangeMessage: (message: string) => void;
}

const MESSAGE_MAX_LENGTH = 2900;

export const MessageField = ({
  message,
  errorMessage,
  onChangeMessage,
}: MessageProps) => {
  return (
    <TextArea
      name="body"
      placeholder="Inserisci un messaggio"
      inputHeaderProps={{
        label: 'Messaggio',
      }}
      maxLength={MESSAGE_MAX_LENGTH}
      value={message}
      onInputChange={(e) => onChangeMessage(e.target.value)}
      errorMessage={errorMessage}
    />
  );
};
