import React from 'react';

export const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '16px', height: '16px' }}
  >
    <path
      d="M9 7h4a1 1 0 010 2H9v4a1 1 0 01-2 0V9H3a1 1 0 110-2h4V3a1 1 0 112 0v4z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);
