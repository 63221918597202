import React from 'react';
import { BodyText, Headline6, TextLink } from '@sbt-web/ui';

import { ShieldIcon, ShopIcon } from './Icons';

import classes from './index.module.scss';

type PaymentMethodsProps = { isPro: boolean };
export const PaymentMethods = ({ isPro }: PaymentMethodsProps) => (
  <div className={classes['payment-methods']}>
    <Headline6 element="h2" classes={[classes.title]}>
      Modalità di pagamento
    </Headline6>

    <div className={classes['payment-info']}>
      <div className={classes['subtitle-with-icon']}>
        <div className={classes['icon-wrapper']}>
          <ShieldIcon />
        </div>
        <BodyText weight="semibold">Pagamento su Subito</BodyText>
      </div>
      <BodyText size="small">
        Paga in totale sicurezza con <b>TuttoSubito</b> e beneficia della
        Protezione Acquisti. I tuoi dati di pagamento non vengono mai condivisi
        con il venditore.
      </BodyText>
    </div>
    {isPro && (
      <div className={classes['payment-info']}>
        <div className={classes['subtitle-with-icon']}>
          <ShopIcon />
          <BodyText weight="semibold">Rivenditore verificato</BodyText>
        </div>
        <BodyText size="small">
          L’identità del Rivenditore viene verificata e validata da Subito.
          Acquistando da un Rivenditore puoi esercitare il{' '}
          <b>diritto di recesso</b> entro 14 giorni.
        </BodyText>
      </div>
    )}

    <TextLink
      size="sm"
      href="https://info.subito.it/affariadistanza/#compra"
      target="_blank"
      rel="noopener"
    >
      Scopri di più sul nostro servizio TuttoSubito
    </TextLink>
  </div>
);
