import { Headline6 } from '@sbt-web/ui';
import React from 'react';
import styles from './index.module.scss';

interface Props {
  name: string;
  url: string;
  handleClick: () => void;
}

export const UserName = ({ name, url, handleClick }: Props) => {
  return (
    <Headline6 classes={[styles.name]}>
      <a href={url} onClick={handleClick}>
        {name}
      </a>
    </Headline6>
  );
};
