import React from 'react';

import { BodyText } from '@sbt-web/ui';

import type { CostWithDiscount } from 'server/detail-requests/ShippingCost';

import classes from './index.module.scss';

export type CostWithDiscountProps = {
  costWithDiscount: CostWithDiscount;
};

export const DiscountedCost = ({ costWithDiscount }: CostWithDiscountProps) => (
  <div className={classes.costWithDiscount}>
    {costWithDiscount.strikethroughCost && (
      <BodyText classes={[classes.strikePrice]} size="small">
        {costWithDiscount.strikethroughCost}
      </BodyText>
    )}
    <BodyText weight="semibold">
      {`${costWithDiscount.partial ? 'da ' : ''}${costWithDiscount.cost}`}
    </BodyText>
  </div>
);
